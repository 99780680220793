import React, { useState, useEffect, useMemo } from 'react'
import {
  List,
  InputItem,
  TextareaItem,
  Picker,
  Switch,
  Toast,
  ActivityIndicator,
  Modal,
} from 'antd-mobile'
import { connect } from 'react-redux'
import { throttle } from 'throttle-debounce'
import qs from 'qs'

import { Button, FloatBottom } from '../../components'
import { request, reLogin } from '../../utility/request'
import { useQuery } from '../../hooks/useQuery'
import { autoAddress, getAreaIdByName } from '../../api'
import store from '../../redux/store'
import './address.css'
import { useTitle } from 'react-use'

class PickTest extends React.Component {
  //省市区选择组件

  state = {
    finalVal: this.props.area,
    district: [],
  }
  componentDidMount() {
    let that = this
    request({
      url: 'Area.AreaList',
      method: 'POST',
      body: qs.stringify({
        platform: 3,
      }),
    }).then(function (res) {
      that.setState({
        district: res.data,
      })
    })
  }
  onOk = (v) => {
    this.setState({
      finalVal: v,
    })
    this.props.handleAreaChange(v)
  }
  render() {
    //const { getFieldProps } = this.props.form;
    const { area } = this.props
    const { district } = this.state
    return (
      <Picker
        extra="省/市/区"
        data={district}
        onOk={this.onOk}
        title="地区选择"
        value={area}
        itemStyle={{ flexBasis: '60%' }}
        // {...getFieldProps('district', {
        //     initialValue: area,
        // })}
      >
        <List.Item arrow="horizontal">地址: </List.Item>
      </Picker>
    )
  }
}

const mapStateToProps = (state) => ({
  wechatAddress: state.wechatAddress,
})

const AddressEdit = (props) => {
  const key = localStorage.getItem('c_token') || ''
  const [loading, setLoading] = useState(true)
  const [MemberName, setName] = useState('')
  const [MemberPhone, setTel] = useState()
  const [area, setArea] = useState([])
  const [detailAddress, setDetailAddress] = useState('')
  const [if_default, setIf_def] = useState(false)
  const [autoAddressValue, setAutoAddressValue] = useState('')

  const query = useQuery()
  const a_id = Number(query.get('a_id') || 0)

  const handleAreaChange = (val) => {
    setArea(val)
  }

  async function handleAutoAddress() {
    if (!autoAddressValue) {
      return
    }

    Toast.loading('请稍候', 0, undefined, true)

    try {
      const { province, city, area, name, mobile, address } = await autoAddress(
        autoAddressValue
      )

      Toast.hide()
      setName(name)
      setTel(mobile)
      setDetailAddress(address)

      if (!province.id || !city.id || !area.id) {
        setArea([])
        Toast.info('请手动输入剩余信息')
        return
      }

      setArea([province.id, city.id, area.id])
      Toast.success('识别成功')
    } catch (err) {
      Toast.hide()
      Toast.info(err.msg, 3)
    }
  }

  useTitle('收货地址', { restoreOnUnmount: true })

  useEffect(() => {
    window.wx.ready(() => {
      window.wx.hideOptionMenu()
    })
  }, [])

  useEffect(() => {
    if (!props.wechatAddress) {
      return
    }

    function parseAddress() {
      Toast.loading('请稍候', 0, undefined, true)

      const {
        userName,
        telNumber,
        provinceName,
        cityName,
        countryName,
        detailInfo,
      } = props.wechatAddress

      setName(userName)
      setTel(telNumber)
      setDetailAddress(detailInfo)

      getAreaIdByName({
        province: provinceName,
        city: cityName,
        area: countryName,
      })
        .then((data) => {
          Toast.hide()
          store.dispatch({
            type: 'SET_WECHAT_ADDRESS',
            payload: null,
          })

          const { provinceId, cityId, areaId } = data

          if (!provinceId || !cityId || !areaId) {
            Toast.info('请重新选择地区')
            return
          }

          setArea([provinceId, cityId, areaId])
        })
        .catch(() => {
          Toast.hide()
        })
    }

    parseAddress()
  }, [props.wechatAddress])

  useEffect(() => {
    if (a_id > 0) {
      request({
        url: 'Buy.OneAddressInfo',
        method: 'POST',
        body: qs.stringify({
          Key: key,
          AddressID: a_id,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          setName(res.data.full_name)
          setTel(res.data.telphone)
          setIf_def(parseInt(res.data.is_default) === 1 ? true : false)
          setDetailAddress(res.data.address)
          setArea([res.data.province_id, res.data.city_id, res.data.area_id])
          setLoading(false)
        } else if (res.flag === 401) {
          reLogin(() =>
            props.history.push(
              `/login?shop_id=${localStorage.getItem(
                'shop_id'
              )}&branch_id=${localStorage.getItem('branch_id')}`
            )
          )
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    } else {
      setLoading(false)
    }
  }, [key, a_id, props.history])

  //useMemo跳过昂贵的省市区联动组件的重新渲染
  const pickerChild = useMemo(
    () => <PickTest handleAreaChange={handleAreaChange} area={area} />,
    [area]
  )

  const submit = throttle(1500, (ignoreAddrWords = 0) => {
    if (MemberName === '') {
      Toast.info('请输入收货人')
      return
    } else if (MemberPhone === '') {
      Toast.info('请输入手机号')
      return
    } else if (area.length < 3) {
      Toast.info('请选择正确地址')
      return
    } else if (detailAddress === '') {
      Toast.info('请输入详细地址')
      return
    }

    const url = a_id === 0 ? 'Buy.AddAddress' : 'Buy.EditAddress'

    request({
      url,
      method: 'POST',
      body: qs.stringify({
        Key: key,
        MemberName,
        MemberPhone,
        Province: area[0],
        City: area[1],
        Area: area[2],
        Address: detailAddress,
        IsDefault: if_default ? '1' : '0',
        AddressID: a_id,
        IgnoreAddrWords: ignoreAddrWords,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        Toast.success('提交成功！', 1, () => props.history.goBack())
      } else if (res.flag === 401) {
        reLogin(() =>
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        )
      } else if (res.flag === 410) {
        Modal.alert('', res.msg, [
          { text: '暂不修改', onPress: () => submit(1) },
          { text: '修改地址' },
        ])
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  })

  return (
    <div>
      {loading ? (
        <div className="loading-example">
          <ActivityIndicator animating />
        </div>
      ) : (
        <>
          <div className="auto">
            <TextareaItem
              className="auto__textarea"
              rows={3}
              maxLength={250}
              placeholder="请粘贴或输入文本，点击“自动识别”姓名、电话和地址"
              onChange={setAutoAddressValue}
            />
            <Button className="auto__button" onClick={handleAutoAddress}>
              识别
            </Button>
          </div>

          <List style={{ marginTop: 20 }}>
            <InputItem
              style={{ fontSize: 15 }}
              value={MemberName}
              onChange={(e) => setName(e)}
              placeholder="请输入收货人姓名"
            >
              收货人:{' '}
            </InputItem>
            <InputItem
              style={{ fontSize: 15 }}
              type="number"
              value={MemberPhone}
              onChange={(e) => setTel(e)}
              placeholder="请输入手机号码"
            >
              手机号码:{' '}
            </InputItem>
            {pickerChild}
            <InputItem
              style={{ fontSize: 15 }}
              value={detailAddress}
              onChange={(e) => setDetailAddress(e)}
              placeholder="输入详细地址"
            >
              详细地址:{' '}
            </InputItem>
            <List.Item
              style={{ fontSize: 15 }}
              extra={
                <Switch checked={if_default} onChange={(e) => setIf_def(e)} />
              }
            >
              设置默认地址
            </List.Item>
          </List>

          <FloatBottom>
            <Button size="large" isFull onClick={() => submit()}>
              提交
            </Button>
          </FloatBottom>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(AddressEdit)
