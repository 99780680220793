import { SWRConfig } from 'swr'

import { WechatProvider } from './contexts/wechat'
import fetcher from './utility/fetcher'
import './App.css'
import ContentWrapper from './pages/content'

export default function App() {
  return (
    // SWR 全局设置：https://swr.vercel.app/docs/global-configuration
    <SWRConfig
      value={{
        fetcher,
        // useSWRImmutable
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        // 请求失败不重试
        shouldRetryOnError: false,
      }}
    >
      <WechatProvider>
        <ContentWrapper />
      </WechatProvider>
    </SWRConfig>
  )
}
