import { useRef, useState } from 'react'
import { useTimeoutFn } from 'react-use'
import clsx from 'clsx'

import styles from './index.module.css'

export type FloatBottomProps = {
  className?: string
  children?: React.ReactNode
}

export function FloatBottom({ className, children }: FloatBottomProps) {
  const floatRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  // 延迟设置高度，避免高度不准确
  useTimeoutFn(() => {
    setHeight(floatRef.current?.clientHeight || 0)
  }, 0)

  return (
    <>
      <div style={{ height }}></div>
      <div ref={floatRef} className={clsx(styles['float-bottom'], className)}>
        {children}
      </div>
    </>
  )
}
