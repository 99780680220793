import React, { useState, useEffect, useCallback, FC } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import qs from 'qs'

import { SafeArea } from '../../components/safe-area'
import { request, reLogin } from '../../utility/request'
import handlePages from '../../utility/handlePages'
import './order.css'

type ItemProps = {
  order_code: string
  apply_type: string
  apply_state: string
  return_id_crypto?: string
  goods_name: string
  goods_image: string
  goods_price: string
  goods_num: string
  goods_total: string
}

const AfterSaleList: FC<RouteComponentProps> = ({ history }) => {
  const key = localStorage.getItem('c_token') || null
  const [pageIndex, setPage] = useState<number>(0)
  const [noMore, setNoMore] = useState<boolean>(false)
  const [list, setList] = useState<Array<ItemProps>>([])

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  useEffect(() => {
    request({
      url: 'AfterSale.AfterSaleList',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        PageIndex: pageIndex,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        if (res.data) {
          if (res.data.length < 10) {
            setNoMore(true)
          }
          setList((prev) => prev.concat(res.data))
        } else {
          setNoMore(true)
        }
      } else if (res.flag === 401 || res.flag === 420) {
        reLogin(() =>
          history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        )
      }
    })
  }, [pageIndex, history, key])

  const handleScorll = useCallback(() => {
    let rest = handlePages()
    if (rest && !noMore && document.documentElement.scrollTop > 0) {
      setPage((n) => n + 1)
    }
  }, [noMore])
  useEffect(() => {
    window.addEventListener('scroll', handleScorll)
    window.addEventListener('touchmove', handleScorll)
    return () => {
      window.removeEventListener('scroll', handleScorll)
      window.removeEventListener('touchmove', handleScorll)
    }
  }, [handleScorll])

  return (
    <div
      style={{ background: '#F2F2F2', minHeight: '100vh', overflow: 'hidden' }}
    >
      {list &&
        list.length > 0 &&
        list.map((item, idx) => <AfterSaleItem key={idx} data={item} />)}
      {noMore && <div className="indicator">到底咯~</div>}
      <SafeArea />
    </div>
  )
}

const AfterSaleItem = ({ data }: { data: ItemProps }) => {
  const status = React.useMemo(() => {
    switch (parseInt(data.apply_state)) {
      case 0:
        return '已取消'
      case 1:
        return '处理中'
      case 2:
        return '已同意'
      case 3:
        return '已拒绝'
      case 4:
        return '已完成'
      default:
        break
    }
  }, [data])

  return (
    <div className="order-item">
      <div
        style={{ color: '#999999' }}
        className="order-post-time order-item-padding"
      >
        <p>订单编号：{data.order_code}</p>
        <span style={{ color: '#C83B4C' }}>
          {parseInt(data.apply_type) === 1 ? '仅退款' : '退货退款'}
        </span>
      </div>
      <Link
        to={`/aftersale_detail?return_id=${data.return_id_crypto}&apply_type=${data.apply_type}`}
      >
        <div className="order-img-box">
          <div className="order-goods-image">
            <img src={data.goods_image} alt="" />
          </div>
          <div className="order-goods-name">
            <h4>{data.goods_name}</h4>
          </div>
        </div>
      </Link>
      <div className="order-item-bot order-item-padding">
        <p>
          共{data.goods_num}件商品 合计￥{data.goods_total}
        </p>
        <div className="order-item-bot-left">
          <button className="del-or-multi" style={{ alignSelf: 'center' }}>
            {status}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AfterSaleList
