import { Input, Icon } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'

export type OrderSearchBarProps = {
  useMulti?: Boolean
}

export function OrderSearchBar({ useMulti }: OrderSearchBarProps) {
  return (
    <div className="order-search-bar">
      <Input
        className="order-search-bar-input"
        placeholder="搜索我的订单"
        prefix={<Icon type="search" style={{ color: '#999' }} />}
        disabled
      />
      <Link
        className="order-search-bar-mask"
        to={`/order/search?use_multi=${useMulti ? 1 : 0}`}
      ></Link>
    </div>
  )
}
