import React, { useState, useEffect } from 'react'
import { countdowning } from './countdown'

interface cdProps {
  countdown: number
  witch: string
  circums: string
  cdCallback?: () => void
}

//倒计时组件
const CountDownWrap = ({ countdown, witch, circums, cdCallback }: cdProps) => {
  const [cdStamp, setCd] = useState<number>(countdown)
  const [cdObj, setCdObj] = useState({ h: '00', m: '00', s: '00' })
  const [ms, setMs] = useState(9)

  useEffect(() => {
    setCd(countdown)
  }, [countdown])

  useEffect(() => {
    setCdObj(countdowning(cdStamp))
    const ctimer = setInterval(() => {
      setCd((n) => n - 1)
      setCdObj(countdowning(cdStamp))
    }, 1000)
    const mstimer = setInterval(() => {
      setMs((num) => (num <= 14 ? 89 : num - 6))
    }, 100)
    if (cdStamp < 0) {
      clearInterval(ctimer)
      setCdObj({ h: '00', m: '00', s: '00' })
      cdCallback && cdCallback()
    }
    return () => {
      clearInterval(ctimer)
      clearInterval(mstimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdStamp])

  return (
    <section className="countdown-wrap">
      {circums === 'homepage' && (
        <p>
          <div style={{ fontSize: 10 }}>{witch}</div>
          <div>
            <span className="cd">{cdObj.h}</span>
            <b className="colon">:</b>
            <span className="cd">{cdObj.m}</span>
            <b className="colon">:</b>
            <span className="cd">{cdObj.s}</span>
            <b className="colon">:</b>
            <span className="cd">{ms}</span>
          </div>
        </p>
      )}
      {circums === 'cart' && (
        <p style={{ marginBottom: 8 }}>
          {witch}
          <span style={{ color: 'var(--color-primary)' }}>
            {cdObj.h}：{cdObj.m}：{cdObj.s}
          </span>
        </p>
      )}
    </section>
  )
}

export default CountDownWrap
