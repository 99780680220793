import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ActivityIndicator } from 'antd-mobile'

import { SafeArea } from '../../components/safe-area'
import { useRequest } from '../../hooks/useRequest'
import { useRequestInfinite } from '../../hooks/useRequestInfinite'
import { useReachEnd } from '../../hooks/useReachEnd'
import './order.css'

function CommentList() {
  const history = useHistory()
  const [type, setType] = useState(1)

  // 获取评价数量
  const { data: countData } = useRequest(
    {
      Url: 'Evaluate.EvaluateCount',
      Key: localStorage.getItem('c_token') || '',
    },
    {
      fallbackData: {
        wait_count: 0,
        already_count: 0,
      },
      revalidateOnMount: true,
    }
  )

  // 获取评价列表，type 1 待评价 2 已评价
  const { list, isLoading, isLoadingMore, isReachingEnd, loadMore } =
    useRequestInfinite((PageIndex) => ({
      Url: type === 1 ? 'Evaluate.EvaluateList' : 'Evaluate.HasEvaluateList',
      Key: localStorage.getItem('c_token') || '',
      PageIndex,
    }))

  // 到达底部页数+1
  useReachEnd(() => {
    if (isLoadingMore || isReachingEnd) return

    loadMore()
  })

  // 隐藏微信分享
  useEffect(() => {
    window.wx.ready(() => {
      window.wx.hideOptionMenu()
    })
  }, [])

  const goComment = (og_id_crypto: string, i: string, n: string) => {
    localStorage.setItem('comment_img', i)
    localStorage.setItem('comment_gname', n)
    history.push(`/post_eva?og_id=${og_id_crypto}`)
  }

  return (
    <div style={{ background: '#f4f4f4', minHeight: '100vh' }}>
      <section className="cl-top">
        <div className="wait" onClick={() => setType(1)}>
          <span className={type === 1 ? 'active' : ''}>待评价</span>
          <span className="num">{countData?.wait_count}</span>
        </div>
        <div className="mid-line"></div>
        <div className="already" onClick={() => setType(2)}>
          <span className={type === 2 ? 'active' : ''}>已评价</span>
          <span className="num">{countData?.already_count}</span>
        </div>
      </section>

      {isLoading ? (
        <ActivityIndicator toast animating text="稍等哦~" />
      ) : (
        <>
          <ul style={{ padding: 13 }} className="comments-whole">
            {list.length > 0 ? (
              list.map((item: any, idx) => (
                <li className="comment-box" key={idx}>
                  <img src={item.goods_image} alt="" />
                  <div>
                    <p className="goods_name_p" style={{ fontSize: 14 }}>
                      {item.goods_name}
                    </p>

                    {parseInt(item.evaluate_state) === 0 ? (
                      <button
                        className="cbtn"
                        style={{ height: 30 }}
                        onClick={() =>
                          goComment(
                            item.og_id_crypto,
                            item.goods_image,
                            item.goods_name
                          )
                        }
                      >
                        去评价
                      </button>
                    ) : (
                      <Link
                        to={`/comments_detail?evaluate_id=${item.evaluate_id}`}
                        className="cbtn"
                      >
                        查看评价
                      </Link>
                    )}
                  </div>
                </li>
              ))
            ) : (
              <p style={{ textAlign: 'center' }}>暂无评价</p>
            )}
          </ul>

          <div className="indicator">
            {isLoadingMore && <ActivityIndicator text="加载中..." />}
            {isReachingEnd && list.length > 0 && '到底咯~'}
          </div>
          <SafeArea />
        </>
      )}
    </div>
  )
}

export default CommentList
