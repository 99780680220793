import React from 'react'
import { List, Toast, InputItem, Picker, Modal } from 'antd-mobile'
import qs from 'qs'
import useSWRImmutable from 'swr/immutable'
import { throttle } from 'throttle-debounce'

import { SafeArea } from '../../components/safe-area'
import { useQuery } from '../../hooks'
import { getGoodsInfo } from '../../api'
import { request } from '../../utility/request'
import '../cart/address.css'

const Item = List.Item

interface cops {
  location: {
    search: string
  }
  [propName: string]: any
}

const tl: Array<any> = [
  [
    {
      label: '仅退款',
      value: 1,
    },
    {
      label: '退货退款',
      value: 2,
    },
  ],
]

const Service: React.FC<cops> = (props) => {
  const {
    match: {
      params: { og_id, num, price },
    },
  } = props
  const [returnType, setRT] = React.useState<number>(1)
  const [reasonType, setRType] = React.useState<number>(1)
  const [remarks, setRemarks] = React.useState<string>('')
  const [realNum, setRealNum] = React.useState<any>(num)
  const [realMoney, setRealMoney] = React.useState<any>(
    (num * price).toFixed(2)
  )
  const [rl, setRl] = React.useState<Array<any>>([])

  const { data: goodsInfo } = useSWRImmutable(
    og_id ? ['Selection.GoodsInfo', og_id] : null,
    () => getGoodsInfo(og_id),
    { revalidateOnMount: true }
  )
  const [showHint, setShowHint] = React.useState(false)
  const query = useQuery()
  const hint =
    goodsInfo?.after_sale_hint ||
    query.get('after_sale_hint') ||
    '退货时请保证商品吊牌、包装完整，不影响二次销售，如有赠品、配件需一起退回'

  React.useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  React.useEffect(() => {
    request({
      url: 'AfterSale.ReasonList',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token'),
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        let arr = res.data
        for (let i = 0; i < arr.length; i++) {
          arr[i]['value'] = i + 1
        }

        setRl([arr])
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }, [])

  const handlePicker = (v: Array<number>) => {
    setRT(v[0])
    v[0] === 1 && setRealNum(num)

    setRealMoney((num * price).toFixed(2))

    // 退货退款时展示横幅并提示
    if (v[0] === 2) {
      setShowHint(true)
      Modal.alert(
        '',
        <div style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>{hint}</div>,
        [{ text: '好的' }]
      )
    } else {
      setShowHint(false)
    }
  }

  const handleReasoner = (v: Array<number>) => {
    setRType(v[0])
  }

  const applyNow = throttle(1500, () => {
    const GoodsNum = parseInt(realNum)
    if (
      returnType === 2 &&
      (!GoodsNum || GoodsNum === 0 || GoodsNum % 1 !== 0)
    ) {
      Toast.fail('请填写正确退货数量')
      return
    } else if (GoodsNum > num) {
      Toast.fail('最多可退' + num + '件')
      return
    }
    if (isNaN(realMoney)) {
      Toast.fail('请输入正确数字')
      return
    }

    request({
      url: 'AfterSale.AddAfterSale',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token'),
        ApplyType: returnType,
        Reason: rl[0][reasonType - 1]['label'],
        DelayType: reasonType,
        Money: realMoney,
        GoodsNum,
        Remark: remarks,
        OgIdCrypto: og_id,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        const { return_id_crypto, apply_type } = res.data

        Toast.success('申请成功！')
        props.history.replace(
          `/aftersale_detail?return_id=${return_id_crypto}&apply_type=${apply_type}`
        )
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  })

  const totalPrice = React.useMemo(
    () =>
      Number(realNum) > Number(num)
        ? (num * price).toFixed(2)
        : (Number(realNum) * price).toFixed(2),
    [realNum, num, price]
  )

  const refundChangeNum = (e: string) => {
    setRealNum(e)
    setRealMoney((parseInt(e) * price).toFixed(2))
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 50 }}>
      {showHint && (
        <div className="tip-gift" style={{ whiteSpace: 'pre-wrap' }}>
          {hint}
        </div>
      )}
      <List style={{ marginTop: 10 }}>
        <Picker
          extra="退款类型"
          data={tl}
          cascade={false}
          onOk={(v: Array<number>) => handlePicker(v)}
          title="类型选择"
          value={[returnType]}
          itemStyle={{ flexBasis: '60%' }}
        >
          <Item arrow="horizontal">退款类型: </Item>
        </Picker>

        {rl.length > 0 && (
          <Picker
            extra="退款类型"
            data={rl}
            cascade={false}
            onOk={(v: Array<number>) => handleReasoner(v)}
            title="类型选择"
            value={[reasonType]}
            itemStyle={{ flexBasis: '60%' }}
          >
            <Item arrow="horizontal">退款原因: </Item>
          </Picker>
        )}

        {returnType === 2 && (
          <InputItem
            maxLength={4}
            moneyKeyboardAlign="right"
            type="number"
            value={realNum}
            onChange={(e: string) => refundChangeNum(e)}
            max={num}
            placeholder={'可修改，最多申请' + num + '件'}
          >
            退货数量(可修改 最多申请{num}):{' '}
          </InputItem>
        )}

        {returnType === 2 && (
          <Item multipleLine extra={totalPrice}>
            退款金额:{' '}
            <Item.Brief>
              不可修改，最多可退（{(num * price).toFixed(2)}）
            </Item.Brief>
          </Item>
        )}

        {returnType === 1 && (
          <InputItem
            moneyKeyboardAlign="right"
            value={realMoney}
            onChange={(e: string) => setRealMoney(e)}
            max={(num * price).toFixed(2)}
            placeholder={'可修改，最多申请' + num}
          >
            退款金额(可修改 最多申请{(num * price).toFixed(2)}):{' '}
          </InputItem>
        )}

        <InputItem value={remarks} onChange={(e: string) => setRemarks(e)}>
          订单备注:
        </InputItem>
      </List>

      <SafeArea />

      <div className="first-sub-bottom">
        <button
          className="first-sub-btn"
          style={{ width: '100%' }}
          onClick={applyNow}
        >
          提交申请
        </button>
      </div>
    </div>
  )
}
export default Service
