import React, { useState, useEffect } from 'react'
import { Toast, ActivityIndicator, Checkbox, Modal } from 'antd-mobile'
import { Link } from 'react-router-dom'
import qs from 'qs'

import { ContactModalButton } from '../../components/contact-modal-button'
import { useQuery, useShopId } from '../../hooks'
import { showConfirm, ShowSimpleContent } from '../../utility/modal'
import { request, reLogin } from '../../utility/request'
import { countdown } from '../../utility/countdown'
import { config } from '../../utility/config'
import iconDate from '../../images/icon-date.png'
import order0 from '../../images/branch/order-0.png'
import order10 from '../../images/branch/order-10.png'
import order20 from '../../images/branch/order-20.png'
import order30 from '../../images/branch/order-30.png'
import order40 from '../../images/branch/order-40.png'
import styles from './orderDetail.module.css'
import './order.css'
import '../cart/address.css'

const OrderDetail = (props) => {
  const query = useQuery()
  const OrderID = query.get('order_id')
  const useMulti = query.get('use_multi') === '1'

  const { branchId } = useShopId()

  const [data, setData] = useState({})
  const [goods, setGoods] = useState([])
  const [gift_list, setGift_list] = useState([])
  const [count, setCount] = useState(6)
  const [loading, setLoading] = useState(true)
  const key = localStorage.getItem('c_token') || ''
  const [cdObj, setCdObj] = React.useState({ h: '00', m: '00', s: '00' })
  const [if_show, setShow] = useState(true)

  // 换购
  const [subOrderModalVisible, setSubOrderModalVisible] = useState(false)
  const [currentOgID, setCurrentOgID] = useState('')
  const [subOrderData, setSubOrderData] = useState({
    cancel_num: 1,
    order_goods_list: [],
    select: 1,
    tip: '请至少选择1件要取消的换购商品',
    warning: '确认取消订单后，将不满足换购条件，换购商品将同时被取消',
  })
  const [subOrders, setSubOrders] = useState([])

  // 子订单取消时拦截(可退)
  const [showCancellableDialog, setShowCancellableDialog] = useState(false)
  const [cancellableTip, setCancellableTip] = useState('')
  const [cancellableSubOrderList, setCancellableSubOrderList] = useState([])

  useEffect(() => {
    setSubOrders(
      subOrderData.order_goods_list.map((v) => ({
        ...v,
        selected: false,
      }))
    )
  }, [subOrderData])

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
    window.wx.error(function (res) {})
  }, [])

  useEffect(() => {
    request({
      url: useMulti ? 'MultiMemberOrder.OrderDetails' : 'Order.OrderDetails',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        OrderIdCrypto: OrderID,
      }),
    }).then(function (res) {
      setLoading(false)
      if (res.flag === 200 && res.data) {
        const { order_goods, gift_list = [] } = res.data
        setData(res.data)
        setGoods(order_goods ? order_goods : [])
        setGift_list(gift_list)
      } else if (res.flag === 401) {
        reLogin(() =>
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        )
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }, [key, OrderID, count, props.history, useMulti])

  useEffect(() => {
    let c = data.count_down
    c === 0 && setShow(false)
    setCdObj(countdown(c))
    const ctimer = setInterval(() => {
      // setCd(n => n -1);
      setCdObj(countdown(c--))
      if (c < 0) {
        clearInterval(ctimer)
        setCdObj({ h: '00', m: '00', s: '00' })
        setShow(false)
      }
    }, 1000)

    return () => {
      clearInterval(ctimer)
    }
  }, [data])

  const orderStateImg = (t) => {
    switch (parseInt(t)) {
      case 0:
        return order0
      case 10:
        return order10
      case 20:
        return order20
      case 30:
        return order30
      case 40:
        return order40
      default:
        return ''
    }
  }

  const goPays = () => {
    localStorage.setItem('member_id', data.member_id)
    localStorage.setItem('pay_money', data.order_total)
    localStorage.setItem('pay_code', data.pay_code)
    window.location.href = '/pay'
  }

  const cancelOrder = (item) => {
    if (+item.order_goods_type === 1) {
      // 换购主商品
      cancelSubOrderApi(item.og_id_crypto)
    } else {
      showConfirm('', '确定要取消吗？', () => {
        cancelSubOrderApi(item.og_id_crypto)
      })
    }

    function cancelSubOrderApi(OgIdCrypto, confirm) {
      const body = {
        Key: key,
        OgIdCrypto,
      }

      if (confirm) {
        body.Confirm = 1
      }

      return request({
        url: 'AfterSale.CancelSubOrder',
        method: 'POST',
        body: qs.stringify(body),
      }).then((res) => {
        if (res.flag === 200) {
          Toast.success(
            '已取消',
            1,
            setCount((a) => a + 1)
          )
        } else if (res.flag === 411) {
          // 二次确认
          showConfirm('', res.msg, () => {
            cancelSubOrderApi(OgIdCrypto, true)
          })
        } else if (res.flag === 410) {
          // 弹窗选择
          getCancelSubOrderDetailApi(OgIdCrypto)
        } else if (res.flag === 463) {
          if (res.data.status === 2) {
            setCancellableTip(res.data.tip)
            setCancellableSubOrderList(res.data.list)
            setShowCancellableDialog(true)
          } else if (res.data.status === 3) {
            Modal.alert('', res.data.tip, [{ text: '我知道了' }])
          }
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    }

    function getCancelSubOrderDetailApi(OgIdsCrypto) {
      request({
        url: 'AfterSale.CancelSubOrderDetail',
        method: 'POST',
        body: qs.stringify({
          Key: key,
          OgIdsCrypto,
        }),
      }).then((res) => {
        if (res.flag === 200) {
          setSubOrderData(res.data)
          setCurrentOgID(OgIdsCrypto)
          setSubOrderModalVisible(true)
        }
      })
    }
  }

  const cancelWhole = () => {
    showConfirm('', '确定要取消吗？', () => {
      request({
        url: 'Order.CancelOrder',
        method: 'POST',
        body: qs.stringify({
          Key: key,
          OrderIdCrypto: OrderID,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success(
            '取消成功！',
            1,
            setCount((a) => a + 1)
          )
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    })
  }

  const subConfirm = (OgIdCrypto) => {
    showConfirm('', '是否确认收货？', () => {
      request({
        url: 'Order.ConfirmSubOrder',
        method: 'POST',
        body: qs.stringify({
          Key: key,
          OgIdCrypto,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success(
            '确认收货成功',
            1,
            setCount((a) => a + 4)
          )
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    })
  }

  const postComments = (og_id_crypto, i, n) => {
    localStorage.setItem('comment_img', i)
    localStorage.setItem('comment_gname', n)
    props.history.push(`/post_eva?og_id=${og_id_crypto}`)
  }

  const handleSubOrderSelect = (selected, index) => {
    setSubOrders((prev) => {
      prev[index].selected = selected
      return [...prev]
    })
  }

  const handleSubOrderConfirm = () => {
    const subIDs =
      subOrderData.select === 0
        ? subOrders.map((item) => item.og_id_crypto)
        : subOrders
            .filter((item) => item.selected)
            .map((item) => item.og_id_crypto)

    request({
      url: 'AfterSale.BatchCancelSubOrder',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        OgIdsCrypto: [currentOgID, ...subIDs].join(','),
      }),
    }).then((res) => {
      if (res.flag === 200) {
        Toast.success('已取消', 1, () => {
          setSubOrderModalVisible(false)
          setCount((a) => a + 1)
        })
      }
    })
  }

  // 跳转商品详情
  const handleRedirectDetail = (item, orderShopId, orderBranchId) => {
    const { goods_id, goods_removed, og_id_crypto, order_code } = item
    const globalShopId = localStorage.getItem('shop_id')
    const globalBranchId = localStorage.getItem('branch_id')

    if (
      globalShopId !== orderShopId || // 不在当前店铺下的订单，不予跳转
      ((globalBranchId || orderBranchId) && globalBranchId !== orderBranchId) || // 不在当前分店下的订单，不予跳转
      useMulti // 多账号详情页，不予跳转
    ) {
      return
    }

    if (Number(goods_removed) === 1) {
      props.history.push(
        `/goods-new?goods_id=${goods_id}&og_id=${og_id_crypto}&order_code=${order_code}`
      )
    } else {
      props.history.push(
        `/goods?goods_id=${goods_id}&shop_id=${orderShopId}&branch_id=${branchId}`
      )
    }
  }

  const cancelSubOrders = () => {
    const ids = cancellableSubOrderList
      .map((item) => item.og_id_crypto)
      .join(',')

    Toast.loading('请稍候', 0, undefined, true)

    request({
      url: 'AfterSale.BatchCancelSubOrder',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        OgIdsCrypto: ids,
      }),
    }).then((res) => {
      if (res.flag === 200) {
        setShowCancellableDialog(false)
        setCount((a) => a + 1)
        Toast.success('取消成功', 1.5)
      }
    })
  }

  return (
    <div
      style={{
        padding: '20px 10px',
        minHeight: '100vh',
        position: 'relative',
        color: '#333',
        paddingBottom: 60,
      }}
    >
      <ActivityIndicator toast text="加载中" animating={loading} />

      {!loading && (
        <>
          <div className="pale-row-line">
            <div
              style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}
            >
              <img
                style={{ width: 45 }}
                src={orderStateImg(data.order_state)}
                alt=""
              />
              <span style={{ color: '#969696', margin: '0 5px' }}>
                收货人：
              </span>
              <p>{data.full_name}</p>
            </div>

            <p style={{ fontSize: 16 }}>{data.user_phone}</p>
          </div>

          {data.edit_address === 0 && (
            <div
              className="pale-row-line"
              style={{ justifyContent: 'flex-start', margin: '10px 0' }}
            >
              <span style={{ color: '#969696' }}>收货地址：</span>
              <p>
                {data.user_area} {data.user_address}
              </p>
            </div>
          )}

          {data.edit_address === 1 && (
            <div
              className="pale-row-line change-add"
              style={{ justifyContent: 'flex-start', margin: '10px 0' }}
            >
              <a
                className="pale-row-line change-order-address"
                href={`/address_list/${data.order_id_crypto}`}
              >
                <div className="pale-row-line">
                  <span style={{ color: '#969696', width: 73 }}>
                    收货地址：
                  </span>
                  <p>
                    {data.user_area} {data.user_address}
                  </p>
                </div>

                <span className="edit-button">更改</span>
              </a>
            </div>
          )}

          {goods &&
            goods.length > 0 &&
            goods.map((item, idx) => (
              <section key={idx}>
                <div
                  className="buy-goods-box"
                  style={{ background: '#FAFAFA', padding: '10px 0' }}
                  onClick={() =>
                    handleRedirectDetail(item, data.shop_id, data.branch_id)
                  }
                >
                  <div className="buy-goods-image">
                    <img
                      src={item.goods_image}
                      alt=""
                      style={
                        +item.order_goods_type === 2
                          ? { width: '70%', height: '70%' }
                          : null
                      }
                    />
                  </div>
                  <div className="buy-goods-info">
                    <div
                      className="buy-goods-box"
                      style={{ flexDirection: 'column' }}
                    >
                      <h3 className="g-titles">
                        {+item.order_goods_type === 2 ? (
                          <img
                            className="exchange-icon"
                            alt=""
                            src={item.exchange_ico}
                          />
                        ) : null}
                        {item.goods_name}
                      </h3>

                      {(data.order_state == 0 ||
                        (item.is_refund == 1 &&
                          ((item.apply_type == 1 &&
                            item.is_cancel_order == 1) ||
                            (item.apply_type == 2 &&
                              item.goods_num == item.r_goods_num)))) && (
                        <span style={{ color: '#FF4F64' }}>订单关闭</span>
                      )}

                      {item.is_refund == 1 &&
                        ((item.apply_type == 1 && item.is_cancel_order == 0) ||
                          (item.apply_type == 2 &&
                            item.goods_num != item.r_goods_num)) && (
                          <span style={{ color: '#FF4F64' }}>部分售后完成</span>
                        )}
                      {parseInt(item.shipping_status) === 0 &&
                        parseInt(data.order_state) === 10 && (
                          <span style={{ color: '#FF4F64' }}>待付款</span>
                        )}
                      {parseInt(item.send_num) > 0 &&
                        parseInt(item.send_num) < parseInt(item.goods_num) && (
                          <span style={{ color: '#FF4F64' }}>部分发货</span>
                        )}
                      {parseInt(item.shipping_status) === 0 &&
                        parseInt(data.order_state) === 20 &&
                        parseInt(item.send_num) === 0 &&
                        parseInt(item.apply_state) !== 4 && (
                          <span style={{ color: '#FF4F64' }}>待发货</span>
                        )}
                      {parseInt(item.shipping_status) === 1 &&
                        parseInt(item.send_num) === parseInt(item.goods_num) &&
                        parseInt(item.refund_state) !== 1 && (
                          <span style={{ color: '#FF4F64' }}>待收货</span>
                        )}
                      {parseInt(item.shipping_status) === 2 &&
                        parseInt(item.refund_state) !== 1 && (
                          <span style={{ color: '#FF4F64' }}>已收货</span>
                        )}
                      {parseInt(item.shipping_status) === 3 &&
                        parseInt(item.refund_state) !== 1 && (
                          <span style={{ color: '#FF4F64' }}>已退货</span>
                        )}
                    </div>
                    <p
                      style={{ padding: '0 12px' }}
                      className={
                        Number(item.pre_sale) === 2
                          ? 'theme-color'
                          : 'theme-yellow-color'
                      }
                    >
                      {item.pre_sale_tip}
                    </p>

                    <p style={{ fontSize: 14, marginLeft: 12 }}>
                      {item.goods_spec}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        marginLeft: 12,
                      }}
                    >
                      <span style={{ color: '#fd5959' }}>
                        ￥{item.goods_payprice}
                      </span>
                      <span>×{item.goods_num}</span>
                    </div>
                  </div>
                </div>
                {/* 待发货和部分发货状态展示定制文案 */}
                {item.customized_remark &&
                  ((parseInt(item.shipping_status) === 0 &&
                    parseInt(data.order_state) === 20 &&
                    parseInt(item.send_num) === 0 &&
                    parseInt(item.apply_state) !== 4) ||
                    (parseInt(item.send_num) > 0 &&
                      parseInt(item.send_num) < parseInt(item.goods_num))) && (
                    <div className={styles.customize}>
                      <img
                        className={styles.customize__icon}
                        src={iconDate}
                        alt="定制图标"
                      />
                      <div className={styles.customize__text}>
                        {item.customized_remark}
                      </div>
                    </div>
                  )}
                {/* 另一个账号查看时不显示操作按钮，保留商品间的留白  */}
                {useMulti ? (
                  <div
                    className="order-item-bot-left"
                    style={{ marginBottom: 20 }}
                  ></div>
                ) : (
                  <div
                    className="order-item-bot-left"
                    style={{ marginBottom: 20 }}
                  >
                    {item.shipping_status > 0 &&
                      parseInt(item.refund_state) === 0 && (
                        <Link
                          style={{ borderRadius: 15, padding: '3px 10px' }}
                          to={{
                            pathname: `/service/${item.og_id_crypto}/${item.goods_num}/${item.goods_price}`,
                            search: new URLSearchParams({
                              after_sale_hint: data.after_sale_hint,
                            }).toString(),
                          }}
                          className="cli-btn"
                        >
                          申请售后
                        </Link>
                      )}
                    {parseInt(item.shipping_status) === 0 &&
                      parseInt(item.refund_state) === 0 &&
                      parseInt(data.order_state) === 20 &&
                      goods.length > 1 &&
                      item.allow_cancel === 1 && (
                        <button
                          className="cli-btn"
                          onClick={() => cancelOrder(item)}
                        >
                          取消订单
                        </button>
                      )}
                    {parseInt(item.shipping_status) === 0 &&
                      parseInt(item.refund_state) === 0 &&
                      parseInt(data.order_state) === 20 &&
                      goods.length === 1 &&
                      item.allow_cancel === 1 && (
                        <button
                          className="cli-btn"
                          onClick={() => cancelWhole()}
                        >
                          取消订单
                        </button>
                      )}
                    {parseInt(item.shipping_status) === 1 &&
                      parseInt(data.order_state) !== 0 &&
                      parseInt(item.send_num) >= parseInt(item.goods_num) &&
                      parseInt(item.apply_state) !== 4 && (
                        <button
                          className="cli-btn"
                          onClick={() => subConfirm(item.og_id_crypto)}
                        >
                          确认收货
                        </button>
                      )}
                    {parseInt(item.refund_state) === 1 &&
                      (parseInt(item.apply_state) === 2 ||
                        parseInt(item.apply_state) === 1) && (
                        <Link
                          style={{ borderRadius: 15, padding: '3px 10px' }}
                          className="cli-btn"
                          to={`/aftersale_detail?return_id=${item.return_id_crypto}&apply_type=${item.apply_type}`}
                        >
                          售后处理中
                        </Link>
                      )}
                    {parseInt(item.apply_state) === 4 && (
                      <Link
                        style={{ borderRadius: 15, padding: '3px 10px' }}
                        className="cli-btn"
                        to={`/aftersale_detail?return_id=${item.return_id_crypto}&apply_type=${item.apply_type}`}
                      >
                        退货/退款成功
                      </Link>
                    )}
                    {item.evaluate_upload_state === 2 && (
                      <Link
                        style={{ borderRadius: 15, padding: '3px 10px' }}
                        className="cli-btn"
                        to={'/comments_detail?evaluate_id=' + item.evaluate_id}
                      >
                        查看评价
                      </Link>
                    )}
                    {item.evaluate_upload_state === 1 && (
                      <button
                        style={{ borderRadius: 15, padding: '3px 10px' }}
                        className="cli-btn"
                        onClick={() =>
                          postComments(
                            item.og_id_crypto,
                            item.goods_image,
                            item.goods_name
                          )
                        }
                      >
                        去评价
                      </button>
                    )}
                  </div>
                )}
              </section>
            ))}

          {gift_list && gift_list.length > 0 && (
            <>
              {/* <p>赠品：共{gift_list.length}件</p> */}
              {gift_list.map((item, idx) => (
                <section key={idx}>
                  <div
                    className="buy-goods-box"
                    style={{ background: '#FAFAFA', padding: '10px 0' }}
                  >
                    <div className="buy-goods-image">
                      <img src={item.goods_image} alt="" />
                    </div>
                    <div className="buy-goods-info">
                      <div className="buy-goods-box">
                        <h3 className="g-titles" style={{ fontSize: 14 }}>
                          {item.goods_name}
                        </h3>
                      </div>

                      <p style={{ fontSize: 14 }}>{item.goods_spec}</p>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: 16,
                        }}
                      >
                        <span>×{item.goods_num}</span>
                      </div>
                    </div>
                  </div>
                </section>
              ))}
            </>
          )}

          <div className="row-line-box" style={{ marginTop: 40, height: 40 }}>
            <p>商品总价：</p>
            <p>{data.goods_total}</p>
          </div>
          <div className="row-line-box" style={{ height: 40 }}>
            <p>快递费：</p>
            <p>{data.freight}</p>
          </div>
          <div className="row-line-box" style={{ height: 40 }}>
            <p>备注：</p>
            <p>{data.order_remark}</p>
          </div>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            合计：{data.order_total}
          </div>
          <p
            className="row-line-box"
            style={{ height: 30, border: 'none', marginTop: 40 }}
          >
            订单编号：{data.order_code}
          </p>
          <p className="row-line-box" style={{ height: 30 }}>
            提交时间：{data.add_times}
          </p>

          <div className="order-item-bot-left">
            <div>
              <ContactModalButton
                shopId={data.shop_id}
                branchId={data.branch_id}
                orderCode={data.order_code}
              />
            </div>
            {data &&
              parseInt(data.order_state) === 10 &&
              !useMulti &&
              data.allow_cancel === 1 && (
                <div>
                  <button className="btn-cancel" onClick={() => cancelWhole()}>
                    取消订单
                  </button>
                  {if_show && (
                    <button className="btn-pay-sec" onClick={goPays}>
                      去支付{cdObj.h}:{cdObj.m}:{cdObj.s}
                    </button>
                  )}
                </div>
              )}
          </div>
        </>
      )}

      {data.show_express === 1 && (
        <div className="logs-btn-fixed">
          <Link to={`/package-detail/${OrderID}?use_multi=${useMulti ? 1 : 0}`}>
            <button className="btn-cancel">查看物流</button>
          </Link>
        </div>
      )}

      <ShowSimpleContent
        visible={subOrderModalVisible}
        formContent={
          <div className="sub-order-modal">
            <div className="sub-order-title">确定取消订单</div>
            <div className="sub-order-tip-title">温馨提示</div>
            <div className="sub-order-tip">{subOrderData.warning}</div>
            <div>{subOrderData.tip}</div>
            <div className="sub-order-items scrollable">
              {subOrders.map((order, index) => (
                <OrderItem
                  key={order.og_id_crypto}
                  selectable={subOrderData.select === 1}
                  {...order}
                  onSelect={(v) => handleSubOrderSelect(v, index)}
                />
              ))}
            </div>
            <div className="sub-order-action">
              <button
                className="sub-order-btn"
                disabled={
                  subOrderData.select === 1 &&
                  subOrders.filter((item) => item.selected).length <
                    subOrderData.cancel_num
                }
                onClick={handleSubOrderConfirm}
              >
                确定取消
              </button>

              <button
                className="sub-order-btn"
                onClick={() => setSubOrderModalVisible(false)}
              >
                再想想
              </button>
            </div>
          </div>
        }
      />

      <ShowSimpleContent
        visible={showCancellableDialog}
        formContent={
          <div className="sub-order-modal">
            <div className="sub-order-title">{cancellableTip}</div>
            <div className="sub-order-items sub-order-items--large scrollable">
              {cancellableSubOrderList.map((order) => (
                <OrderItem key={order.og_id_crypto} {...order} />
              ))}
            </div>
            <div className="sub-order-action">
              <button
                className="sub-order-btn"
                onClick={() => setShowCancellableDialog(false)}
              >
                再想想
              </button>
              <button className="sub-order-btn" onClick={cancelSubOrders}>
                确定取消
              </button>
            </div>
          </div>
        }
      />
    </div>
  )
}

function OrderItem(props) {
  const {
    selectable,
    order_goods_type,
    exchange_ico,
    goods_image,
    goods_name,
    goods_spec,
    goods_price,
    goods_num,
    selected,
    onSelect,
  } = props

  const imageUrl = goods_image.startsWith('http')
    ? goods_image
    : `//${config.imageCdn}/${goods_image}`

  function handleCheckboxChange(e) {
    onSelect(e.target.checked)
  }

  return (
    <div className="order-item-container order-item-container--small">
      <div className="order-item-image">
        <div className="order-item-checkbox">
          {selectable ? (
            <Checkbox checked={selected} onChange={handleCheckboxChange} />
          ) : null}
        </div>
        <img alt="" src={imageUrl} />
      </div>
      <div className="order-item-info">
        <div className="order-item-name">
          {+order_goods_type === 2 ? (
            <img className="exchange-icon" alt="" src={exchange_ico} />
          ) : null}
          {goods_name}
        </div>
        <div className="order-item-spec">规格：{goods_spec}</div>
        <div className="order-item-bottom">
          <div className="order-item-price">¥{goods_price}</div>
          <div className="order-item-num">x{goods_num}</div>
        </div>
      </div>
    </div>
  )
}

export default OrderDetail
