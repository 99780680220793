import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ActivityIndicator } from 'antd-mobile'
import qs from 'qs'

import { SafeArea } from '../../components/safe-area'
import { useShopId, useToken } from '../../hooks'
import { request, reLogin } from '../../utility/request'
import { showForceLoginModal } from '../../utility/utils'

interface secProps {
  testa?: number
  testb?: string
  [propName: string]: any
}

interface secItem {
  goods_image?: string
  goods_name?: string
  activity_type?: string | number
  goods_id?: string | number
  [propName: string]: any
}

const SeckillList: React.FC<secProps> = (props) => {
  const [key] = useToken()
  const { shopId, branchId } = useShopId()
  const [noMore, setNoMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState<number>(1)
  const [seclist, setSecList] = useState<Array<object>>([])

  React.useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  React.useEffect(() => {
    setLoading(true)
    request({
      url: 'Activity.SeckillList',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        Key: key,
        Type: type,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        if (res.data) {
          // if(res.data.length < 10){
          //     setNoMore(true)
          // }
          setSecList((prev) => prev.concat(res.data))
        } else {
          setNoMore(true)
        }
      } else if (res.flag === 401) {
        setNoMore(true)
        showForceLoginModal(res.msg)
      } else if (res.flag === 420) {
        setNoMore(true)
        reLogin(function () {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        })
      }
      setLoading(false)
    })
  }, [type, shopId, props.history, key])

  const handleSwitch = (n: number): void => {
    if (n === type) {
      return
    }
    setSecList([])
    setType(n)
  }
  return (
    <div className="secWrap">
      <div className="sec_wrap">
        <div
          style={{ width: '50%' }}
          className={type === 1 ? 'sec_act' : ''}
          onClick={() => handleSwitch(1)}
        >
          秒杀中
        </div>
        <div
          style={{ width: '50%' }}
          className={type === 2 ? 'sec_act' : ''}
          onClick={() => handleSwitch(2)}
        >
          预热中
        </div>
      </div>

      {!loading ? (
        <ul className="sec_list_box">
          {seclist &&
            seclist.length > 0 &&
            seclist.map((item: secItem, idx) => (
              <li key={idx}>
                <Link
                  className="sec_item_wraper"
                  to={
                    '/goods?goods_id=' +
                    item.goods_id +
                    '&shop_id=' +
                    shopId +
                    '&branch_id=' +
                    branchId +
                    '&activity_id=0'
                  }
                >
                  {type === 2 && <p className="time_tag">{item.time_text}</p>}
                  <img src={item.seckill_image} alt="" />
                  <div className="sec_item_bot">
                    <p style={{ margin: '5px 0' }} className="sec_goods_name">
                      {item.goods_name}
                    </p>
                    <div className="want_more">
                      <p>
                        秒
                        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                          ￥{item.goods_price}
                        </span>
                      </p>
                      <p className="see_detail">了解详情</p>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      ) : (
        <div className="loading-example" style={{ justifyContent: 'center' }}>
          <ActivityIndicator animating text="稍等哦~" size="large" />
        </div>
      )}

      {noMore && <p style={{ textAlign: 'center' }}>暂无更多~</p>}
      <SafeArea />
    </div>
  )
}

export default SeckillList
