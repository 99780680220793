import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Icon } from 'antd'
import { Checkbox, Toast, ActivityIndicator, Button } from 'antd-mobile'
import QueueAnim from 'rc-queue-anim'
import { connect } from 'react-redux'
import qs from 'qs'
import clsx from 'clsx'
import TabBarWrapper from '../tabbar/tabbar'
import { Stepper } from '../../components'
import { SafeArea } from '../../components/safe-area'
import { useShopId, useToken } from '../../hooks'
import { useShopError } from '../../data'
import { request, reLogin } from '../../utility/request'
import { showConfirm } from '../../utility/modal'
import { countdown } from '../../utility/countdown'
import './cart.css'

const mapStateToProps = (state) => {
  return {
    ifLogin: state.ifLogin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (res) => {
      localStorage.setItem('c_token', res.token)
      dispatch({ type: 'LOGIN' })
    },
  }
}

const Cart = (props) => {
  const [count, setCount] = useState(1) // force update
  const [loading, setLoading] = useState(true)
  const [total, setTot] = useState('0.00')
  const [totalNum, setTotNum] = useState(0)
  const [cartList, setCartList] = useState([])
  const [nowTime, setNowTime] = useState()
  const [notAllowList, setNotAllowList] = useState([])
  const shop_id = localStorage.getItem('shop_id') || 0
  const key = localStorage.getItem('c_token')

  const { shopId, branchId } = useShopId()
  const { data: shopError } = useShopError()

  useToken()

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  useEffect(() => {
    if (!props.ifLogin || shopError) {
      return
    }

    request({
      url: 'MultiCart.Cartlist',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || null,
        ShopID: shop_id,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        let a = res.data.cart_list ? res.data.cart_list : []
        setCartList(
          a.map((v) => ({
            ...v,
            goods_list: v.goods_list.filter((g) => +g.allow_select !== 0),
          }))
        )
        setNotAllowList(
          [].concat(
            ...a.map((v) => v.goods_list.filter((g) => +g.allow_select === 0))
          )
        )
        setTot(res.data.total_price)
        setTotNum(res.data.cart_count)
        setNowTime(res.server_time)
      } else if (res.flag === 411) {
        setCartList([])
        setNotAllowList([])
      } else if (res.flag === 401) {
        reLogin(() => {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        }, 'cart')
      } else {
        Toast.info(res.msg)
      }
      setLoading(false)
    })
  }, [count, shop_id, props.ifLogin, props.history, shopError])

  const selectAll = (e, confirm) => {
    e.stopPropagation()
    const IsSelect = e.target.checked ? '1' : '0'
    setLoading(true)

    const body = {
      Key: key,
      IsSelect,
      ShopID: shop_id,
    }
    if (confirm) {
      body.Confirm = 1
    }

    request({
      url: 'MultiCart.AllSelCart',
      method: 'POST',
      body: qs.stringify(body),
    }).then(function (res) {
      setLoading(false)
      if (res.flag === 200) {
        setCount((n) => n + 1)
      } else if (res.flag === 410) {
        showConfirm('', res.msg, () => selectAll(e, true))
      } else {
        Toast.fail(res.msg)
      }
    })
  }

  const freshUpdate = () => {
    setLoading(true)
    setCount((n) => n + 3)
  }

  const judgeIfSelectAll = React.useMemo(() => {
    for (var i = 0; i < cartList.length; i++) {
      for (var j = 0; j < cartList[i].goods_list.length; j++) {
        if (parseInt(cartList[i].goods_list[j].is_select) === 0) {
          return false
        }
      }
    }
    return true
  }, [cartList])

  const submitOrder = () => {
    if (parseInt(totalNum) === 0) {
      Toast.info('请选择一样结算商品~', 1.2)
      return
    }
    localStorage.setItem('buy_type', 20)
    props.history.push('/buy/buystep')
  }

  const Inevitable = () => {
    reLogin(function () {
      props.history.replace(
        `/login?shop_id=${localStorage.getItem(
          'shop_id'
        )}&branch_id=${localStorage.getItem('branch_id')}`
      )
    }, 'cart')
  }

  if (shopError) {
    return <Redirect to={`/?shop_id=${shopId}&branch_id=${branchId}`} />
  }

  return (
    <div
      style={{ minHeight: '100vh', paddingBottom: 50, paddingTop: 10 }}
      className={'grey-background'}
    >
      {props.ifLogin ? (
        <div style={{ paddingBottom: 50 }}>
          <ActivityIndicator toast text="数据加载中.." animating={loading} />

          <QueueAnim type={['left', 'right']} leaveReverse>
            {cartList.length > 0
              ? cartList.map((item) =>
                  item.group_id !== 0 ? (
                    <div key={item.group_id} className="cart-activity-card">
                      <div className="cart-activity-card-header">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.exchange_tip.replace(
                              item.tip_red_part,
                              `<span style="color:#FF4F64">${item.tip_red_part}</span>`
                            ),
                          }}
                        />
                        <Link to={`/exchange?id=${item.group_id}`}>
                          {item.exchange_operation}
                          <Icon type="right" />
                        </Link>
                      </div>
                      {[...item.goods_list, ...item.exchange_list].map(
                        (goods) => (
                          <CartItem
                            key={goods.cart_id}
                            data={goods}
                            groupId={item.group_id}
                            nowTime={nowTime}
                            freshUpdate={freshUpdate}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    item.goods_list.map((goods) => (
                      <div key={goods.cart_id} className="cart-activity-card">
                        <CartItem
                          data={goods}
                          nowTime={nowTime}
                          freshUpdate={freshUpdate}
                        />
                      </div>
                    ))
                  )
                )
              : null}

            {notAllowList.length > 0 ? (
              <>
                <div className="cannot-calculate">不可结算</div>
                {notAllowList.map((ite) => (
                  <div key={ite.cart_id} className="cart-activity-card">
                    <CartItem
                      data={ite}
                      nowTime={nowTime}
                      freshUpdate={freshUpdate}
                    />
                  </div>
                ))}
              </>
            ) : null}
          </QueueAnim>

          <SafeArea />

          {cartList.length > 0 ? (
            <div className="cartlist-bottom">
              <div
                style={{
                  width: '25%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={judgeIfSelectAll}
                  className="cbox"
                  style={{ paddingTop: '16%', height: '100%' }}
                  onClick={(e) => selectAll(e)}
                >
                  全选
                </Checkbox>
              </div>
              <div
                style={{ width: '45%', textAlign: 'left', alignSelf: 'center' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    color: '#333',
                  }}
                >
                  <span
                    style={{
                      alignSelf: 'center',
                      color: '#BCBCBC',
                      fontSize: 10,
                    }}
                  >
                    不含运费
                  </span>
                  <p
                    style={{
                      alignSelf: 'center',
                      marginLeft: 15,
                      fontSize: 16,
                    }}
                  >
                    合计:<span style={{ color: '#FF6D8C' }}>{total}</span>
                  </p>
                </div>
              </div>

              <button
                style={{
                  width: '30%',
                  color: '#fff',
                  background: '#FF6D8C',
                  fontSize: 16,
                }}
                onClick={submitOrder}
              >
                结算({totalNum})
              </button>
            </div>
          ) : null}

          {cartList.length === 0 && notAllowList.length === 0 ? (
            <div style={{ marginTop: 100, textAlign: 'center' }}>
              购物车空空如也~
            </div>
          ) : null}
        </div>
      ) : (
        <div style={{ padding: 30, width: 200, margin: '50px auto' }}>
          <Button
            className="inevitable-login-btn"
            onClick={Inevitable}
            type="primary"
            style={{
              borderColor: 'var(--color-primary)',
              background: 'var(--color-primary)',
            }}
          >
            登录
          </Button>
        </div>
      )}
      <TabBarWrapper />
    </div>
  )
}

const CartItem = (props) => {
  const { data, groupId = 0, nowTime } = props
  const { seckill_start_time, seckill_end_time } = data
  // 倒计时时间（单位：秒）
  const countdownTime =
    seckill_start_time > nowTime
      ? seckill_start_time - nowTime
      : seckill_end_time > nowTime
      ? seckill_end_time - nowTime
      : 0
  const [cdObj, setCdObj] = useState(
    countdown(+data.activity_type === 1 ? countdownTime : 0)
  )
  const history = useHistory()
  const { shopId, branchId } = useShopId()

  const goods_id = data.goods_id
  const activity_type = +data.activity_type
  const activity_id = data.activity_id
  const allow_select = +data.allow_select === 1

  const goodsPath = `/goods?goods_id=${goods_id}&shop_id=${shopId}&branch_id=${branchId}&activity_id=${activity_id}`

  const lines = React.useMemo(() => {
    if (
      parseInt(data.activity_type) === 0 &&
      (parseInt(data.goods_state) !== 1 ||
        parseInt(data.goods_shop_state) !== 1)
    ) {
      return '已下架'
    } else if (parseInt(data.activity_type) === 0 && data.goods_storage < 1) {
      return '库存不足'
    } else if (
      parseInt(data.activity_type) === 1 &&
      data.seckill_count_down > 0
    ) {
      return '预热中'
    } else if (
      parseInt(data.activity_type) === 1 &&
      (parseInt(data.goods_shop_state) !== 1 || data.overSeckill < 0)
    ) {
      return '已下架'
    } else if (parseInt(data.activity_type) === 1 && data.goods_storage < 1) {
      return '库存不足'
    } else if (+data.goods_storage < +data.goods_num) {
      // 库存小于加购数
      return '库存不足'
    }
  }, [data])

  React.useEffect(() => {
    if (activity_type !== 1) {
      return
    }

    let time = countdownTime
    const timer = setInterval(() => {
      setCdObj(countdown(time--))
      if (time < 0) {
        clearInterval(timer)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [activity_type, countdownTime])

  const redirectToDetailPage = () => {
    if (!allow_select && !['预热中', '库存不足'].includes(lines)) return
    history.push(goodsPath)
  }

  const editNum = (number, confirm) => {
    const body = {
      Key: localStorage.getItem('c_token'),
      CartID: data.cart_id,
      GoodsNum: number,
      ShopID: localStorage.getItem('shop_id'),
    }
    if (confirm) {
      body.Confirm = 1
    }

    request({
      url: 'MultiCart.EditCartquantity',
      method: 'POST',
      body: qs.stringify(body),
    }).then(function (res) {
      if (res.flag === 200) {
        props.freshUpdate()
      } else if (res.flag === 410) {
        showConfirm('', res.msg, () => editNum(number, true))
      } else {
        Toast.fail(res.msg)
      }
    })
  }

  const doDelete = () => {
    if (+groupId !== 0 && data.activity_type !== 7) {
      // 如果是换购主商品
      deleteApi()
    } else {
      showConfirm('', '确定要删除商品吗？', () => {
        deleteApi(true)
      })
    }

    function deleteApi(confirm) {
      const body = {
        Key: localStorage.getItem('c_token'),
        CartID: data.cart_id,
        ShopID: localStorage.getItem('shop_id'),
      }
      if (confirm) {
        body.Confirm = 1
      }

      request({
        url: 'MultiCart.DelCart',
        method: 'POST',
        body: qs.stringify(body),
      }).then(function (res) {
        if (res.flag === 200) {
          props.freshUpdate()
        } else if (res.flag === 410) {
          showConfirm('', res.msg, () => deleteApi(true))
        } else {
          Toast.fail(res.msg)
        }
      })
    }
  }

  const handleSelChange = (e, confirm) => {
    const body = {
      Key: localStorage.getItem('c_token'),
      CartID: data.cart_id,
      IsSelect: e.target.checked ? 1 : 0,
      ShopID: localStorage.getItem('shop_id'),
    }
    if (confirm) {
      body.Confirm = 1
    }

    request({
      url: 'MultiCart.SelCart',
      method: 'POST',
      body: qs.stringify(body),
    }).then(function (res) {
      if (res.flag === 200) {
        props.freshUpdate()
      } else if (res.flag === 410) {
        showConfirm('', res.msg, () => handleSelChange(e, true))
      } else {
        Toast.fail(res.msg)
      }
    })
  }

  return (
    <div className="multicart-item-container">
      <div className="multicart-item">
        <div className="multicart-item-left">
          {activity_type !== 7 ? (
            <Checkbox
              checked={parseInt(data.is_select) === 1}
              disabled={!allow_select}
              onChange={(e) => handleSelChange(e)}
            />
          ) : null}

          <div className="multicart-item-image">
            <div
              className="multicart-item-image-wrapper"
              style={
                activity_type === 7
                  ? { width: '70%', height: '70%' }
                  : { width: '100%', height: '100%' }
              }
              onClick={redirectToDetailPage}
            >
              <img src={data.goods_image} alt="" />
              {!allow_select && (
                <div className="multicart-item-image-mask">{lines}</div>
              )}
            </div>
          </div>
        </div>

        <div className="multicart-item-right">
          <div
            className="multicart-item-name twolines-hidden"
            onClick={redirectToDetailPage}
          >
            {activity_type === 1 && (
              <span className="multicart-item-seckill-icon">秒</span>
            )}
            {activity_type === 7 ? (
              <img
                className="cart-exchange-ico"
                src={data.exchange_ico}
                alt=""
              />
            ) : null}
            {data.goods_name}
          </div>
          <div className="multicart-item-spec">
            <span>{data.goods_spec}</span>
            <Icon
              type="delete"
              className="multicart-item-delete"
              onClick={doDelete}
            />
          </div>
          <div className="multicart-item-action">
            <span
              className="multicart-item-price"
              onClick={redirectToDetailPage}
            >
              ￥{data.goods_shop_price}
            </span>
            {activity_type === 7 ? (
              <span>x{data.goods_num}</span>
            ) : (
              <Stepper
                style={{ width: 100, fontSize: 12 }}
                disabled={!allow_select}
                useMax={true}
                min={1}
                max={data.goods_storage}
                defaultValue={data.goods_num}
                onChange={editNum}
              />
            )}
          </div>
          {data.pre_sale_tip ? (
            <div
              className={clsx(
                'multicart-item-tip',
                +data.pre_sale === 2 ? 'theme-color' : 'theme-yellow-color'
              )}
            >
              {data.pre_sale_tip}
            </div>
          ) : null}
        </div>
      </div>

      {activity_type === 1 && (
        <div
          className="multicart-item-tip multicart-item-tip--padding"
          style={{ color: !allow_select ? 'var(--color-primary)' : '#FF6D8C' }}
        >
          距离活动{!allow_select ? '开始' : '结束'}还有{cdObj.h}小时{cdObj.m}分
          {cdObj.s}秒
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
