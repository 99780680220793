import clsx from 'clsx'
import styles from './index.module.css'

type SafeAreaProps = {
  position?: 'top' | 'bottom'
}

export function SafeArea({ position = 'bottom' }: SafeAreaProps) {
  return (
    <div
      className={clsx(styles['safe-area'], styles[`safe-area--${position}`])}
    ></div>
  )
}
