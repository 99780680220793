import React, { useState, FC, useMemo } from 'react'
import { Button, List, Toast, InputItem, Picker, Modal } from 'antd-mobile'
import qs from 'qs'
import { throttle } from 'throttle-debounce'
import { ContactModalButton } from '../../components/contact-modal-button'
import { SafeArea } from '../../components/safe-area'
import { useSearchParams, useShopId } from '../../hooks'
import { request, reLogin } from '../../utility/request'
import { showConfirm } from '../../utility/modal'
import { config } from '../../utility/config'
import styles from './buystep.module.css'
import './address.css'

const Item = List.Item
const Brief = Item.Brief

interface BuyProp {
  location?:
    | {
        search: string
      }
    | any
  [propName: string]: any
}
interface GoodsItemProps {
  goods_image?: string
  goods_name?: string
  goods_spec?: string
  goods_shop_price?: string | number
  goods_num?: string | number
  [propName: string]: any
}

const BuyStep: FC<BuyProp> = (props) => {
  const {
    match: {
      params: { address_id },
    },
  } = props
  const [remarks, setRemarks] = useState<string>()
  const [deliverTotal, setDeliverTotal] = useState(1)
  const [order_total, setTotal] = useState<string | number>()
  const [address_info, setAddressInfo] = useState<any>({})
  const [order_freight, setFreight] = useState<string | number>()
  const [express_list, setExpressList] = useState<Array<any> | null>()
  const [express_info, setExpressInfo] = useState<{
    express_id: string
    express_remarks: string
  } | null>()
  const [glist, setGlist] = useState<Array<object> | null>()
  const [deliver, setDeliver] = useState<string | number>(0)
  const [nowTime, setNowTime] = useState(0)
  const [count, setCount] = useState(0)
  const [selfPickup, setSelfPickup] = useState(false)
  const [serviceTip, setServiceTip] = useState({
    is_show: 0,
    content: '',
    cancel_button: '',
    confirm_button: '',
    goods_list: [] as Array<{
      goods_image: string
      goods_name: string
      goods_spec: string
    }>,
  })
  // 是否已经展示过提示
  const [searchParams, setSearchParams] = useSearchParams()
  const isServiceTipShowed = searchParams.get('service_tip_showed') === '1'

  //const aid = props.location?parseInt(props.location.search.split("=")[1]):0
  const buy_type: string = localStorage.getItem('buy_type') || '20'

  const { shopId, branchId } = useShopId()

  React.useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  React.useEffect(() => {
    return function cleanup() {
      localStorage.removeItem('addressid') //清楚addressid缓存，再次进来用的默认的0
    }
  }, [])

  React.useEffect(() => {
    let _url = parseInt(buy_type) === 10 ? 'Buy.AtonceBuyStep1' : 'Buy.BuyStep1'
    let badyTits: object =
      parseInt(buy_type) === 10
        ? {
            ShopID: localStorage.getItem('shop_id') || 0,
            GoodsID: localStorage.getItem('atonce_goods_id'),
            AddressID: address_id,
            GoodsNum: localStorage.getItem('atonce_goods_num'),
            Key: localStorage.getItem('c_token') || '',
            ActivityID: localStorage.getItem('atonce_activity_id'),
            ActivityType: localStorage.getItem('atonce_activity_type'),
            ExpressID: deliver,
          }
        : {
            Key: localStorage.getItem('c_token') || '',
            AddressID: address_id,
            ExpressID: deliver,
            ShopID: localStorage.getItem('shop_id') || 0,
          }
    request({
      url: _url,
      method: 'POST',
      body: qs.stringify(badyTits),
    }).then(function (res) {
      if (res.flag === 200) {
        setDeliverTotal(res.data.deliver_total)
        setGlist(res.data.cart_list)
        setTotal(res.data.order_total)
        setFreight(res.data.freight)
        setExpressInfo(res.data.express_info)
        setAddressInfo(res.data.address_info ? res.data.address_info : {})
        setSelfPickup(Number(res.data.pickup_in_store) === 1)
        setServiceTip(res.data.goods_service_tip)
        let el = res.data.express_list
        if (el) {
          for (var i = 0; i < el.length; i++) {
            el[i].value = el[i].express_id
            el[i].label = el[i].express_name
          }
          //setDeliver([res.data.express_info.express_id])
        }

        setExpressList([el])
      } else if (res.flag === 401) {
        reLogin(() =>
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        )
      } else {
        res.msg && Toast.fail(res.msg, 2, () => props.history.goBack())
      }
    })
  }, [deliver, buy_type, props.history, count, address_id])

  React.useEffect(() => {
    localStorage.setItem('skip_add', '6')
  }, [])

  const handleAreaChange = (val: Array<string>) => {
    setDeliver(val[0])
  }

  const ifOthers = useMemo(() => {
    const { area_info = '' } = address_info

    return (area_info.indexOf('其他') > 0 || area_info.indexOf('海外')) > 0
      ? true
      : false
  }, [address_info])

  const atonceBuySub = throttle(2000, (ignoreAddrWords = 0) => {
    setNowTime(Date.now())
    if (Date.now() - nowTime < 2000) {
      return
    }

    if (ifOthers) {
      showConfirm(
        '',
        '收货地址带有“其他市”或“其他区”或“海外”，将会影响系统发货，请修改地址',
        () => {
          props.history.push('/address_edit?a_id=' + address_info.address_id)
        }
      )
      return
    }

    if (address_info && address_info.address_id) {
      let url: string =
        parseInt(buy_type) === 10 ? 'Buy.AtonceBuyStep2' : 'Buy.BuyStep2'
      let paramsObj: object =
        parseInt(buy_type) === 10
          ? {
              ShopID: localStorage.getItem('shop_id') || 0,
              GoodsID: localStorage.getItem('atonce_goods_id'),
              GoodsNum: localStorage.getItem('atonce_goods_num'),
              AddressID: address_info.address_id,
              OrderMessage: remarks,
              ExpressID: deliver,
              ActivityType: localStorage.getItem('atonce_activity_type') || 0,
              OrderFrom: 3,
              Key: localStorage.getItem('c_token') || '',
              IgnoreAddrWords: ignoreAddrWords,
            }
          : {
              ShopID: localStorage.getItem('shop_id') || 0,
              Key: localStorage.getItem('c_token') || '',
              AddressID: address_info.address_id,
              OrderMessage: remarks,
              ExpressID: deliver,
              ActivityType: localStorage.getItem('atonce_activity_type') || 0,
              OrderFrom: 3,
              IgnoreAddrWords: ignoreAddrWords,
            }
      Toast.loading('', 0)
      request({
        url: url,
        method: 'POST',
        body: qs.stringify(paramsObj),
      }).then(function (res) {
        Toast.hide()
        if (res.flag === 200) {
          localStorage.setItem('pay_code', res.data.pay_code)
          localStorage.setItem('member_id', res.data.member_id)
          localStorage.setItem('pay_money', res.data.pay_money)
          window.location.replace('/pay')
        } else if (res.flag === 401) {
          reLogin(() =>
            props.history.push(
              `/login?shop_id=${localStorage.getItem(
                'shop_id'
              )}&branch_id=${localStorage.getItem('branch_id')}`
            )
          )
        } else if (res.flag === 410) {
          Modal.alert('', res.msg, [
            { text: '暂不修改', onPress: () => atonceBuySub(1) },
            {
              text: '修改地址',
              onPress: () =>
                props.history.push(
                  `/address_edit?a_id=${address_info.address_id}`
                ),
            },
          ])
        } else if (res.flag === 420) {
          Modal.alert(
            '提示',
            <div>
              {(res.msg as string).split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>,
            [{ text: '知道了' }]
          )
        } else {
          res.msg && Toast.fail(res.msg, 1, () => props.history.goBack())
        }
      })
    } else {
      Toast.info('请选择一个地址', 1.3)
    }
  })

  const btnMemo = useMemo(() => {
    let btnStr = ''
    let fn: () => void
    switch (deliverTotal) {
      case 1:
        btnStr = '提交订单'
        fn = atonceBuySub
        break
      case 2:
        btnStr = '移除不可购买商品'
        fn = () => {
          request({
            url: 'MultiCart.UnselectNotDeliver',
            method: 'POST',
            body: qs.stringify({
              Key: localStorage.getItem('c_token') || '',
              ShopID: localStorage.getItem('shop_id') || 0,
              AddressID: address_info.address_id,
            }),
          }).then(function (res) {
            if (res.flag === 200) {
              setCount((n) => n + 1)
            } else if (res.flag === 401) {
              reLogin(() =>
                props.history.push(
                  `/login?shop_id=${localStorage.getItem(
                    'shop_id'
                  )}&branch_id=${localStorage.getItem('branch_id')}`
                )
              )
            } else {
              Toast.fail(res.msg, 1)
            }
          })
        }
        break
      case 3:
        btnStr = '重新选择商品'
        fn = () => {
          props.history.goBack()
        }
        break
      default:
        btnStr = '提交订单'
        fn = atonceBuySub
        break
    }

    return (
      <button
        disabled={!address_info}
        className={address_info ? 'first-sub-btn' : 'first-sub-disabled-btn'}
        onClick={() => fn()}
      >
        {btnStr}
      </button>
    )
  }, [address_info, deliverTotal, atonceBuySub, props.history])

  function goAddressList() {
    window.location.replace(
      `/address_list?skip=3&service_tip_showed=${isServiceTipShowed ? 1 : 0}`
    )
  }

  function handleTipCancel() {
    props.history.go(-1)
  }

  function handleTipConfirm() {
    setServiceTip((prevTip) => ({
      ...prevTip,
      is_show: 0,
    }))
    searchParams.set('service_tip_showed', '1')
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <div
      style={{ background: '#f4f4f4', minHeight: '100vh', paddingBottom: 50 }}
    >
      {!address_info && (
        <div className="top-change-tip">
          <p>发货及运费情况以实际选择地址为准</p>
        </div>
      )}
      <List>
        <Item className="address-title" arrow="horizontal" multipleLine>
          <div
            style={{ display: 'block', minHeight: 35 }}
            onClick={goAddressList}
          >
            {address_info ? (
              <div>
                姓名：{address_info.full_name} {address_info.telphone}
                <Brief>
                  收货地址：{address_info.area_info} {address_info.address}
                </Brief>
              </div>
            ) : (
              <>
                姓名：<Brief>收货地址：~</Brief>
              </>
            )}
          </div>
        </Item>
      </List>
      <div
        style={{
          padding: 5,
          background: '#FFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ContactModalButton shopId={shopId} branchId={branchId} />
        {selfPickup && (
          <a
            href="/self-pickup/store"
            style={{ color: 'var(--color-primary)' }}
          >
            门店自提
          </a>
        )}
      </div>
      <List>
        {glist &&
          glist.length > 0 &&
          glist.map((item: GoodsItemProps, idx) => (
            <GoodsItem item={item} key={idx} />
          ))}

        <InputItem value={remarks} onChange={(e: string) => setRemarks(e)}>
          订单备注
        </InputItem>
      </List>
      <List style={{ marginTop: 10, marginBottom: 20 }}>
        {express_list && express_info && (
          <Picker
            extra="配送方式："
            data={express_list}
            cascade={false}
            onOk={(v: Array<string>) => handleAreaChange(v)}
            title="地区选择"
            value={[express_info.express_id]}
            itemStyle={{ flexBasis: '60%' }}
            format={(labels) => {
              let item: string[] = []
              express_list[0].forEach((it: any) => {
                if (it.express_name.indexOf(labels[0]) > -1) {
                  item.push(it.express_name)
                }
              })
              return item
            }}
          >
            <Item arrow="horizontal">选择快递: </Item>
          </Picker>
        )}

        <Item extra={'￥' + order_freight}>快递费</Item>

        {express_info && (
          <Item wrap>
            <p style={{ color: '#F8C884' }}>{express_info.express_remarks}</p>
          </Item>
        )}
      </List>

      <SafeArea />

      <div className="first-sub-bottom">
        <div>
          <p>合计金额：</p>
          <p style={{ color: '#FF6D8C', fontSize: 18 }}>￥{order_total}</p>
        </div>
        {btnMemo}
      </div>

      {serviceTip.is_show === 1 && !isServiceTipShowed && (
        <Modal
          className={styles['tip-popup']}
          transparent
          visible={serviceTip.is_show === 1}
        >
          <div className={styles['tip-popup__title']}>{serviceTip.content}</div>
          <div className={styles['tip-popup__scroll']}>
            {(serviceTip.goods_list || []).map((item, index) => (
              <div key={index} className={styles['tip-popup__goods']}>
                <img
                  className={styles['tip-popup__goods__image']}
                  src={item.goods_image}
                  alt="商品图片"
                />
                <div className={styles['tip-popup__goods__right']}>
                  <div className={styles['tip-popup__goods__name']}>
                    {item.goods_name}
                  </div>
                  <div className={styles['tip-popup__goods__spec']}>
                    {item.goods_spec}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles['tip-popup__actions']}>
            <Button
              className={styles['tip-popup__action']}
              onClick={handleTipCancel}
            >
              {serviceTip.cancel_button}
            </Button>
            <Button
              className={styles['tip-popup__action']}
              type="primary"
              onClick={handleTipConfirm}
            >
              {serviceTip.confirm_button}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

const GoodsItem: FC<GoodsItemProps> = (props) => {
  const { item } = props

  return (
    <div key={item.goods_id} className="buy-goods-box pd-24 mb-12">
      {item.deliver && item.deliver !== 1 && (
        <div className="step-cover">
          <span>{item.deliver_tip}</span>
          <img
            src={`//${config.imageCdn}/seckill/5b8012e9293fd23b04171b3c744c1d92.png`}
            alt=""
          />
        </div>
      )}
      <div className="buy-goods-image">
        <img src={item.goods_image} alt="" />
      </div>
      <div className="buy-goods-info">
        <h3 className="g-titles">{item.goods_name}</h3>
        <p style={{ fontSize: 14 }}>{item.goods_spec}</p>
        <div className="buy-step-goods-item">
          <span style={{ color: '#fd5959' }}>￥{item.goods_shop_price}</span>
          <span>×{item.goods_num}</span>
        </div>

        {item.deliver === 1 && (
          <p
            className={
              Number(item.pre_sale) === 2 ? 'theme-color' : 'theme-yellow-color'
            }
          >
            {item.pre_sale_tip}
          </p>
        )}
      </div>
    </div>
  )
}

export default BuyStep
