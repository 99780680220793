import { useState, useEffect, useMemo } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { useTitle } from 'react-use'
import { connect } from 'react-redux'
import {
  Carousel,
  WingBlank,
  WhiteSpace,
  Toast,
  ActivityIndicator,
} from 'antd-mobile'
import { Icon } from 'antd'
import dayjs from 'dayjs'
import qs from 'qs'
import { parse } from 'node-html-parser'
import {
  ShowEditModal,
  ShowSimpleContent,
  showConfirm,
} from '../../utility/modal'
import CountDownWrap from '../public/CountDownComponent'
import { ShopInfo, NonDelivery, Stepper, BackTop } from '../../components'
import {
  useErrorResponse,
  useQuery,
  useRequest,
  useSearchParams,
  useShopId,
  useShopView,
  useToken,
} from '../../hooks'
import { request, reLogin } from '../../utility/request'
import { showForceLoginModal } from '../../utility/utils'
import { config } from '../../utility/config'
import './list.css'

let isInBlack = false // C端用户被拉黑

const mapStateToProps = (state) => {
  return {
    ifLogin: state.ifLogin,
  }
}

function GoodsDetail(props) {
  const query = useQuery()
  const goods_id = query.get('goods_id')
  const activity_id = query.get('activity_id') || 0
  const forceLogin = query.get('force_login') === '1'

  const { shopId, branchId, setShopId } = useShopId({ removeBranchId: true })

  const [activity_type, setActivityType] = useState(0)
  const [goods_info, setGoodsInfo] = useState({})
  const [image_list, setImageList] = useState([])
  const [goods_body, setGoodsBody] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [goods_service, setGoodsService] = useState('')
  const [noExpressArea, setNoExpressArea] = useState('')
  const [nonDeliveryList, setNonDeliveryList] = useState([])
  const [goods_ser_name, setGoodsSerName] = useState('')
  const [spec_list, setSpecList] = useState([])
  const [share_g_img, setShareImg] = useState(null)
  const [qr_url, setQr] = useState('')
  const [detailError, setDetailError] = useState()

  const [visible, setVisible] = useState(false)
  const [minible, setMinible] = useState(false)
  // const [refreshing, setRefreshing] = useState(false);
  const [current_spec, setCurrentSpec] = useState({})
  const [goods_spec, setGoods_spec] = useState('')

  const [eva_count, setEcount] = useState(0)
  const [cart_count, setCartCount] = useState(0)
  const [evaObj, setEobj] = useState([])
  const [count, setCount] = useState(3)
  const [buttonType, setButtonType] = useState(1)
  const [is_quota, setIs_quota] = useState(0) //限购
  const [quota_num, setQuota_num] = useState(0)
  const [cdStamp, setCd] = useState(0)
  const [goods_spec_list, setGoods_spec_list] = useState([])

  const [ifStartObj, setIfStartObj] = useState({
    dateText: '',
    noticeText: '',
    spikeStart: false,
  })
  const [contentType, setContentType] = useState(1)

  const [exchangeInfo, setExchangeInfo] = useState({
    exchange: 0,
    exchange_activity_id: '',
    exchange_highlight_tip: '',
    exchange_ico: '',
    exchange_tip: '',
  })
  // 是否是换购商品
  const exchangeable = +activity_type === 7
  // 是否参加换购活动
  const discountVisible = exchangeInfo.exchange === 1 && !exchangeable

  const hasComments = eva_count > 0

  // 吊牌价，初始值 0.00 表示没有吊牌价
  const [maxSellPrice, setMaxSellPrice] = useState('0.00')
  // 是否可以购买
  const [canBuy, setCanBuy] = useState(true)

  const [key] = useToken()

  const getGoodsDetail = () => {
    const data = {
      ShopID: shopId,
      BranchID: branchId,
      GoodsID: goods_id,
      Key: key,
    }

    request({
      url: 'Selection.GoodsDetails',
      method: 'POST',
      body: qs.stringify(data),
    }).then(function (res) {
      if (res.flag === 200 && res.data) {
        if (
          !res.data.spec_lists &&
          !res.data.image_list &&
          !res.data.shop_goods_info &&
          !res.data.goods_image
        ) {
          Toast.info('该商品已下架', 1.3, () => props.history.goBack())
          return
        }
        const {
          seckill_info,
          shop_goods_info,
          image_list,
          evaluation_count,
          goods_body,
          goods_service,
          service_name,
          not_deliver_area,
          not_deliver_area_list,
          color_list,
          current_goods_spec,
          goods_spec,
          goods_image,
          is_quota,
          quota_num,
          goods_spec_list,
          exchange_info,
          activity_type,
          can_buy,
        } = res.data

        setCanBuy(Boolean(can_buy))
        setGoods_spec_list(goods_spec_list)
        setMaxSellPrice(
          goods_spec_list.find((g) => g.goods_id == goods_id)?.max_sell_price ||
            '0.00'
        )
        setActivityType(activity_type)

        if (seckill_info.length === 0 && parseInt(activity_type) === 1) {
          window.location.href = `/goods?goods_id=${goods_id}&shop_id=${shopId}&branch_id=${branchId}`
        } else if (parseInt(activity_type) === 1 && seckill_info) {
          if (seckill_info.seckill_state === 0) {
            Toast.fail('秒杀活动已结束', 1.5, () => {
              props.history.goBack()
            })
            return
          }
          let _o = seckill_info
          _o.pre_sale = shop_goods_info.pre_sale
          _o.pre_sale_tip = shop_goods_info.pre_sale_tip
          _o.goods_price = shop_goods_info.goods_price
          _o.goods_name = shop_goods_info.goods_name
          _o.orig_price = shop_goods_info.goods_lowest_price
          _o.goods_freight = shop_goods_info.goods_freight
          setGoodsInfo(_o)
          judgeStartOrEnd(
            res.server_time,
            seckill_info.start_time,
            seckill_info.end_time
          )
        } else if (
          res.data.seckill_info &&
          parseInt(seckill_info.seckill_state) === 0
        ) {
          Toast.fail('秒杀活动已结束', () => {
            props.history.goBack()
          })
          return
        } else {
          setGoodsInfo(shop_goods_info)
        }
        setImageList(image_list)
        setEcount(parseInt(evaluation_count))
        setGoodsBody(goods_body)
        setGoodsService(goods_service)
        setNoExpressArea(not_deliver_area)
        setNonDeliveryList(not_deliver_area_list || [])

        setGoodsSerName(service_name)
        setSpecList(color_list)
        setCurrentSpec(
          current_goods_spec
            ? current_goods_spec
            : { color_id: '', spec_id: '' }
        )
        let s = ''
        for (var i in goods_spec) {
          s += ' ' + goods_spec[i]
        }
        setGoods_spec(s)
        setShareImg(goods_image)
        setIs_quota(is_quota || 0)
        setQuota_num(quota_num || 0)
        setExchangeInfo(exchange_info)
      } else {
        if (res.flag === 401) {
          setDetailError(res)
          showForceLoginModal(res.msg)
        } else {
          res.msg && Toast.fail(res.msg)
          setTimeout(() => {
            props.history.push(`/?shop_id=${shopId}&branch_id=${branchId}`)
          }, 1300)
        }
      }
    })
  }

  useTitle('商品详情', { restoreOnUnmount: true })
  // PV 请求
  useShopView({ shopId, key })

  useErrorResponse(detailError)

  // 设置 goods_id
  useEffect(() => {
    localStorage.setItem('atonce_goods_id', goods_id)
  }, [goods_id])

  useEffect(() => {
    setTimeout(() => {
      window.wx.ready(function () {
        window.wx.showOptionMenu()

        if (goods_info && share_g_img) {
          window.wx.updateAppMessageShareData?.({
            title: goods_info.goods_name ? goods_info.goods_name : '品质商品', // 分享标题
            desc: '【有人@你】你有一个分享尚未点击', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: share_g_img, // 分享图标
            success: function () {
              // 设置成功
            },
          })
          window.wx.updateTimelineShareData?.({
            title: goods_info.goods_name ? goods_info.goods_name : '品质商品', // 分享标题
            desc: '【有人@你】你有一个分享尚未点击', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: share_g_img, // 分享图标
            success: function () {
              // 设置成功
            },
          })
        }
      })
    }, 1000)
  }, [goods_info, share_g_img])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!key && forceLogin) {
      return
    }

    if (!shopId) {
      return
    }

    getGoodsDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, forceLogin, shopId])

  useEffect(() => {
    if (hasComments) {
      if (!key && forceLogin) {
        return
      }

      request({
        url: 'Selection.GoodsEvaluateList',
        method: 'POST',
        body: qs.stringify({
          ShopID: shopId || '',
          GoodsID: goods_id,
          Key: key,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          setEobj(res.data)
        } else if (res.flag === 401) {
          showForceLoginModal(res.msg)
        }
      })
    }
    // sku 切换引起的 goods_id 变化不用刷新评论
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasComments, shopId, key, forceLogin])

  useEffect(() => {
    if (!shopId || !key) {
      return
    }

    request({
      url: 'MultiCart.Cartlist',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        ShopID: shopId,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setCartCount(res.data.cart_count)
        isInBlack = Number(res.data.is_in_black) === 1
      }
    })
  }, [count, shopId, key])

  // 获取店铺信息
  const {
    data: shopInfo,
    error: shopError,
    isLoading: shopInfoLoading,
  } = useRequest(
    forceLogin && !key
      ? null
      : {
          Url: 'Shop.ShopInfo',
          ShopID: shopId || '',
          Key: key,
          Client: 'c',
        }
  )

  useEffect(() => {
    if (shopInfo?.shop_id) {
      setShopId(shopInfo.shop_id)
    }
  }, [shopInfo, setShopId])

  const judgeStartOrEnd = (nowTime, startTime, endTime) => {
    let time = ''
    if (nowTime < startTime) {
      const dateText = dayjs(new Date(startTime * 1000)).format(
        'MM月DD日 HH:mm 开抢'
      )
      time = startTime - nowTime
      setCd(parseInt(time))
      setIfStartObj({
        dateText,
        noticeText: '距离活动开始',
        spikeStart: false,
      })
    } else if (nowTime < endTime) {
      const dateText = dayjs(new Date(endTime * 1000)).format(
        'MM月DD日 HH:mm 结束'
      )
      time = endTime - nowTime
      setCd(parseInt(time))
      setIfStartObj({
        dateText,
        noticeText: '距离活动结束',
        spikeStart: true,
      })
    }
  }

  // useEffect(() => {
  //     setCdObj(countdown(cdStamp))
  //     const ctimer = setInterval(() => {
  //         setCd(n => n - 1);
  //         setCdObj(countdown(cdStamp));
  //     }
  //         , 1000)
  //     const mstimer = setInterval(() => {
  //         setMs((num) => {
  //             return num <= 14 ? '89' : num - 7
  //         })
  //     }
  //         , 100)
  //     if (cdStamp < 0) {
  //         clearInterval(ctimer);
  //         setCdObj({ h: '00', m: '00', s: '00' });
  //     }
  //     return () => {
  //         clearInterval(ctimer);
  //         clearInterval(mstimer);
  //     };
  // }, [cdStamp])

  const handleAdding = () => {
    if (!canBuy) {
      return
    }
    setVisible(true)
    setButtonType(1)
  }
  const handleServiceSupport = () => {
    setVisible(true)
    setButtonType(2)
    setContentType(1)
    setModalContent(goods_service)
  }
  const handleNoExpressArea = () => {
    setVisible(true)
    setButtonType(2)
    setContentType(2)
    setModalContent(nonDeliveryList)
  }
  const handleCancel = () => {
    setVisible(false)
  }
  const reloadCart = () => {
    setVisible(false)
    setCount((s) => s + 1)
  }

  const turnOnMini = () => {
    Toast.loading()
    request({
      url: 'Home.GetWxqrcodeGoods',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        GoodsID: goods_id,
        ActivityID: activity_id,
        ActivityType: activity_type,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setQr(res.data)
        setMinible(true)
        Toast.hide()
      }
    })
  }

  const turnOffMini = () => {
    setMinible(false)
  }

  const previewImgs = (current) => {
    const urls = image_list.map((item) => item.goods_image)
    window.wx &&
      window.wx.previewImage?.({
        current: current, // 当前显示图片的http链接
        urls, // 需要预览的图片http链接列表
      })
  }

  const resetDis = (obj = {}) => {
    const {
      goods_spec,
      current_goods_spec,
      pre_sale_tip = '',
      pre_sale = 2,
    } = obj
    setCurrentSpec(
      current_goods_spec ? current_goods_spec : { color_id: '', spec_id: '' }
    )

    let s = ''
    for (var i in goods_spec) {
      s += ' ' + goods_spec[i]
    }
    setGoods_spec(s)
    setGoodsInfo((gd) => {
      return {
        ...gd,
        pre_sale_tip,
        pre_sale,
      }
    })
  }

  // 跳转换购页面
  const handleExchange = () => {
    if (!props.ifLogin) {
      showConfirm('', '您还未登录，是否先登录？', () => {
        reLogin(function () {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        }, 'goods' + window.location.search)
      })
    } else {
      props.history.push(`/exchange?id=${exchangeInfo.exchange_activity_id}`)
    }
  }

  const handleHTMLClick = (e, html) => {
    // 预览图片
    if (e.target.tagName === 'IMG') {
      const current = e.target.getAttribute('src')
      const urls = Array.from(parse(html).querySelectorAll('img')).map((el) =>
        el.getAttribute('src')
      )

      window.wx.previewImage?.({
        current,
        urls,
      })
    }
  }

  if (shopError) {
    return <Redirect to={`/?shop_id=${shopId}&branch_id=${branchId}`} />
  }

  return (
    <>
      {goods_info && Object.keys(goods_info).length !== 0 ? (
        <>
          <div
            style={{
              paddingBottom: `calc(50px + var(--safe-area-inset-bottom))`,
              position: 'relative',
            }}
          >
            <Icon
              type="left"
              size="md"
              style={{
                position: 'absolute',
                top: 10,
                left: 7,
                padding: 4,
                zIndex: 322,
              }}
              onClick={() => props.history.goBack()}
            />
            {image_list && image_list.length > 0 && (
              <Carousel key={456} infinite>
                {image_list.map((item, idx) => (
                  <img
                    key={idx}
                    src={item.goods_image}
                    alt=""
                    style={{
                      width: '100vw',
                      minHeight: '100vw',
                      maxWidth: 768,
                      maxHeight: 768,
                      objectFit: 'cover',
                    }}
                    onClick={() => previewImgs(item.goods_image)}
                  />
                ))}
              </Carousel>
            )}

            {parseInt(activity_type) === 1 && (
              <div className="seckill-row-reminder">
                <img src={require('../../images/spike.png').default} alt="" />
                <div className="cd-row">
                  {cdStamp ? (
                    <>
                      <span className="cd-date">{ifStartObj.dateText}</span>
                      <CountDownWrap
                        countdown={cdStamp}
                        witch={ifStartObj.noticeText}
                        circums="homepage"
                        cdCallback={() => getGoodsDetail()}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            )}

            <WingBlank key={78}>
              <div
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {/* 商品名称 */}
                <h4 style={{ fontSize: 16 }}>{goods_info.goods_name}</h4>
                {/* 商品价格 */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    marginTop: 8,
                    lineHeight: 1,
                    fontSize: 12,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {parseInt(activity_type) === 1 && (
                      <span className="seck-icon">秒杀</span>
                    )}
                    <h3 style={{ color: '#FF4F65' }}>
                      {exchangeable && (
                        <span style={{ fontSize: 16 }}>换购价：</span>
                      )}
                      ¥
                      <span style={{ marginLeft: 2, fontSize: 20 }}>
                        {goods_spec_list.find((g) => g.goods_id == goods_id)
                          ?.goods_price || goods_info.goods_price}
                      </span>
                    </h3>
                    {!exchangeable && maxSellPrice !== '0.00' && (
                      <span className="max-sell-price">
                        吊牌价：¥{maxSellPrice}
                      </span>
                    )}
                  </div>

                  <span>
                    运费：
                    {goods_info.goods_freight
                      ? goods_info.goods_freight
                      : '0.00'}
                  </span>
                </div>
              </div>
            </WingBlank>
            <WhiteSpace style={{ backgroundColor: '#f4f4f4', height: 7 }} />
            <WingBlank
              style={{ textAlign: 'center', padding: '5px 0' }}
              key={69}
            >
              <Spec label="规格" desc={goods_spec} onClick={handleAdding} />
              <Spec
                label="服务"
                truncate
                desc={goods_ser_name}
                onClick={handleServiceSupport}
              />
              {noExpressArea ? (
                <Spec
                  label="商品不发货地区"
                  truncate
                  desc={noExpressArea}
                  onClick={handleNoExpressArea}
                />
              ) : null}
              {/* 换购标签 */}
              {discountVisible ? (
                <Spec
                  label="优惠"
                  desc={
                    <span
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      <img
                        alt=""
                        style={{
                          display: 'inline-block',
                          width: 'auto',
                          height: 16,
                          marginRight: 4,
                        }}
                        src={exchangeInfo.exchange_ico}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: exchangeInfo.exchange_tip.replace(
                            exchangeInfo.exchange_highlight_tip,
                            `<span style="color:#FF4F64">${exchangeInfo.exchange_highlight_tip}</span>`
                          ),
                        }}
                      />
                    </span>
                  }
                  onClick={handleExchange}
                />
              ) : null}
            </WingBlank>
            <WhiteSpace style={{ backgroundColor: '#f4f4f4', height: 7 }} />

            {/* 店铺信息 */}
            {!shopInfoLoading && (
              <>
                <WingBlank key={431} style={{ padding: '12px 0' }}>
                  <ShopInfo {...shopInfo} />
                </WingBlank>
                <WhiteSpace style={{ backgroundColor: '#f4f4f4', height: 7 }} />
              </>
            )}

            {/* 用户评价 */}
            {hasComments && evaObj.length > 0 ? (
              <>
                <div className="goods-comments">
                  <div className="proevatitle">
                    <div className="ltitle">宝贝评价({eva_count}人)</div>
                    <Link
                      to={
                        '/evaluate?goods_id=' +
                        goods_id +
                        '&eva_count=' +
                        eva_count
                      }
                      className="standardmore"
                    >
                      <p className="rprecent">全部查看</p>
                      <Icon
                        type="right"
                        style={{ color: '#FF4F64', fontSize: 14 }}
                      />
                    </Link>
                  </div>
                  <div>
                    <div className="row-line-box" style={{ border: 'none' }}>
                      <div style={{ display: 'flex' }}>
                        <img
                          src={`//${config.imageCdn}/activity/b4a18d33e13be6d08006470b7f0a8d1c.jpeg?imageslim`}
                          style={{ width: 35, height: 35, borderRadius: '50%' }}
                          alt="la"
                        />
                        <span
                          style={{
                            color: '#969696',
                            alignSelf: 'center',
                            marginLeft: 6,
                          }}
                        >
                          匿 名
                        </span>
                      </div>
                      <span style={{ color: '#969696' }}>
                        {evaObj[0].add_times}
                      </span>
                    </div>
                    <p style={{ padding: '0 10px 10px 10px' }}>
                      {evaObj[0].eva_content}
                    </p>
                  </div>
                </div>
                <WhiteSpace style={{ backgroundColor: '#f4f4f4', height: 7 }} />
              </>
            ) : null}

            <WingBlank
              style={{ textAlign: 'center', padding: '10px 0' }}
              key={629}
            >
              <h3 className="purchase-remind">
                <Icon type="bars" />
                <span style={{ paddingLeft: 5 }}>详情</span>
              </h3>
              {goods_info.goods_notes && (
                <div
                  dangerouslySetInnerHTML={{ __html: goods_info.goods_notes }}
                  className="goods_detail"
                  onClick={(e) => handleHTMLClick(e, goods_info.goods_notes)}
                />
              )}
              <div
                dangerouslySetInnerHTML={{ __html: goods_body }}
                className="goods_detail"
                onClick={(e) => handleHTMLClick(e, goods_body)}
              />
            </WingBlank>
            <WhiteSpace style={{ backgroundColor: '#f4f4f4', height: 7 }} />

            <ShowEditModal
              visible={visible}
              popupVal={true}
              handleCancel={handleCancel}
              anType="slide-up"
              transparent={false}
              closable={buttonType === 1}
              formContent={
                buttonType === 1 ? (
                  <Adding
                    goods_id={goods_info.goods_id}
                    goods_info={goods_info}
                    spec_list={spec_list}
                    current_spec={current_spec}
                    activity_type={activity_type}
                    activity_id={exchangeInfo.exchange_activity_id}
                    exchange={exchangeInfo.exchange}
                    handleCancel={handleCancel}
                    reloadCart={reloadCart}
                    is_quota={is_quota}
                    quota_num={quota_num}
                    goods_spec_list={goods_spec_list}
                    if_warmingup={
                      Number(activity_type) === 1 && !ifStartObj.spikeStart
                        ? true
                        : false
                    }
                    resetDis={resetDis}
                  />
                ) : (
                  <ServiceContent
                    contentType={contentType}
                    content={modalContent}
                    handleCancel={handleCancel}
                  />
                )
              }
            />
          </div>

          <div className="g-fixed-menu">
            <Link className="purchase-script" to="/cart">
              {cart_count > 0 && <b className="num-tips">{cart_count}</b>}
              <Icon type="shopping-cart" style={{ fontSize: '24px' }} />
              <span style={{ fontSize: 12 }}>购物车</span>
            </Link>

            {parseInt(activity_type) === 1 && !ifStartObj.spikeStart ? (
              <div
                className="purchase-script"
                onClick={() => Toast.info('点击右上角分享给朋友或朋友圈哦~')}
              >
                <Icon type="share-alt" style={{ fontSize: '24px' }} />
                <span style={{ fontSize: 12 }}>分享</span>
              </div>
            ) : (
              <Link className="purchase-script" to="/member">
                <Icon type="user" style={{ fontSize: '24px' }} />
                <span style={{ fontSize: 12 }}>我的</span>
              </Link>
            )}

            <div className="g-btn-group">
              {!canBuy ? (
                <button className="adding-btn-line" disabled>
                  商品已下架
                </button>
              ) : parseInt(activity_type) === 7 ? (
                <button
                  className="adding-btn-line"
                  disabled={exchangeInfo.exchange == 0}
                  onClick={() =>
                    props.history.push(
                      `/exchange?id=${exchangeInfo.exchange_activity_id}`
                    )
                  }
                >
                  {exchangeInfo.exchange == 0 ? '活动已结束' : '查看换购活动'}
                </button>
              ) : (
                <>
                  {parseInt(activity_type) === 1 && !ifStartObj.spikeStart ? (
                    <button
                      className="adding-btn-allLine"
                      onClick={handleAdding}
                    >
                      加入购物车
                    </button>
                  ) : (
                    <button className="adding-btn" onClick={handleAdding}>
                      加入购物车
                    </button>
                  )}

                  {parseInt(activity_type) === 1 && !ifStartObj.spikeStart ? (
                    ''
                  ) : (
                    <button className="purchase-now-btn" onClick={handleAdding}>
                      立即购买
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="loading-example">
          <ActivityIndicator animating text="稍等哦~" size="large" />
        </div>
      )}

      <ShowSimpleContent
        visible={minible}
        handleCancel={turnOffMini}
        formContent={<img src={qr_url} style={{ width: 220 }} alt="lf" />}
      />
      <BackTop bottom={110} />
      <img
        src={require('../../images/miniPro.png').default}
        className="open-mini-btn"
        onClick={turnOnMini}
        alt="min"
      />
    </>
  )
}

const AddCom = (props) => {
  const { spec_list, current_spec } = props
  const [num, setNum] = useState(1)
  const [RGS, setRGS] = useState(0) //真实商品库存
  const [skuArr, setSkuArr] = useState([])
  const [cid, setCid] = useState(current_spec.color_id || '')
  const [sid, setSid] = useState(current_spec.spec_id || '')
  const shopId = localStorage.getItem('shop_id') || 0
  const [specLists, setSpecLists] = useState()
  const [skuNum, setSkuNum] = useState(1)
  const [storageList, setStorageList] = useState([])

  const [goodsImageBig, setGoodsImageBig] = useState('')
  const [goodsImage, setGoodsImage] = useState('')
  const [goodsPrice, setGoodsPrice] = useState('')

  // 从 query 拿 goods_id
  const [searchParams, setSearchParams] = useSearchParams()
  const queryGoodsId = searchParams.get('goods_id') || props.goods_id
  const { branchId } = useShopId()
  // 正在获取库存
  const [loading, setLoading] = useState(false)

  const handleStepChange = (x) => {
    if (isNaN(x)) {
      setNum(1)
      return
    }
    setNum(x)
  }

  useEffect(() => {
    if (props.buttonType === 2 || !shopId) {
      return
    }
    if (skuNum === 2 && !sid) {
      return
    }

    setLoading(true)

    request({
      url: 'Selection.GoodsSpecStorage',
      method: 'POST',
      body: qs.stringify({
        GoodsIdList: props.goods_spec_list
          .filter(({ is_del }) => is_del === 0)
          .map(({ goods_id }) => goods_id)
          .join(','),
      }),
    })
      .then(function (res) {
        const { data = {} } = res
        let goodsId = queryGoodsId // 默认为 query 上 id

        props.goods_spec_list.forEach((it) => {
          const {
            goods_spec,
            goods_price,
            goods_image,
            goods_image_big,
            goods_id,
          } = it
          const keys = goods_spec && Object.keys(goods_spec)
          if (!goods_spec) {
            goodsId = goods_id
            setGoodsImage(goods_image)
            setGoodsImageBig(goods_image_big)
            setGoodsPrice(goods_price)
          } else if (keys.length === 1) {
            if (keys.indexOf(cid.toString()) > -1) {
              goodsId = goods_id
              setGoodsImage(goods_image)
              setGoodsImageBig(goods_image_big)
              setGoodsPrice(goods_price)
            }
          } else {
            if (
              keys.indexOf(cid.toString()) > -1 &&
              keys.indexOf(sid.toString()) > -1
            ) {
              goodsId = goods_id
              setGoodsImage(goods_image)
              setGoodsImageBig(goods_image_big)
              setGoodsPrice(goods_price)
            }
          }
        })

        const { goods_storage_list = [] } = data
        setStorageList(goods_storage_list)
        // 更新库存
        setRGS(
          goods_storage_list.filter(({ goods_id }) => goodsId === goods_id)[0]
            ?.goods_storage || 0
        )

        const newSpec = props.goods_spec_list.find(
          (it) => it.goods_id == goodsId
        )

        props.resetDis(newSpec)

        // 更新url中的goods_id
        if (window.location.pathname === '/goods') {
          searchParams.set('goods_id', goodsId)
          setSearchParams(searchParams, { replace: true })
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }, [cid, sid])

  const doBuyNow = () => {
    if (!props.ifLogin) {
      showConfirm('', '您还未登录，是否先登录？', () => {
        reLogin(function () {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        }, 'goods' + window.location.search)
      })
    } else {
      if (parseInt(RGS) <= 0) {
        Toast.info('没有库存了哦，看看别的吧~', 1)
        return
      }

      if (isInBlack) {
        Toast.fail('抱歉，您的账号被限制，无法下单')
        return
      }

      localStorage.setItem('atonce_activity_id', props.activity_id)
      localStorage.setItem('atonce_activity_type', props.activity_type)
      localStorage.setItem('atonce_goods_num', num)
      localStorage.setItem('buy_type', 10)
      props.history.push('/buy/buystep')
    }
  }
  const doAddingCart = () => {
    if (!props.ifLogin) {
      showConfirm('', '您还未登录，是否先登录？', () => {
        reLogin(function () {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        }, 'goods' + window.location.search)
      })
    } else {
      if (parseInt(RGS) <= 0) {
        Toast.info('没有库存了哦，看看别的吧~', 1)
        return
      }

      request({
        url: 'MultiCart.AddCart',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token'),
          GoodsID: localStorage.getItem('atonce_goods_id'),
          ShopID: shopId,
          GoodsNum: num,
          ActivityID: props.activity_id || 0,
          ActivityType: props.activity_type,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success('添加购物车成功~', 1, () => {
            props.reloadCart()
          })
        } else {
          res.msg && Toast.fail(res.msg, 1.5)
        }
      })
    }
  }

  useEffect(() => {
    spec_list && setSkuNum(spec_list.length)
  }, [spec_list])

  useEffect(() => {
    let arr = []

    arr = props.goods_spec_list.map((item) => {
      // 从SKU接口库存中更新库存
      const index = storageList.findIndex(
        (s) => Number(s.goods_id) === Number(item.goods_id)
      )
      const goods_storage =
        index > -1 ? storageList[index].goods_storage : item.goods_storage

      return {
        id: Object.keys(item.goods_spec || []).map(Number),
        goods_id: Number(item.goods_id),
        goods_storage: Number(goods_storage),
      }
    })

    setSpecLists(spec_list)
    setSkuArr(arr)
  }, [storageList, spec_list, props.goods_spec_list])

  const switchSpecNow = (idx, father_idx) => {
    if (father_idx === 0 && cid !== idx) {
      setCid(idx)
    } else if (father_idx === 1 && sid !== idx) {
      setSid(idx)
    }
  }

  const cancelSpecActive = (idx, father_idx) => {
    setRGS(0)
    if (father_idx === 0) {
      setCid('')
    } else if (father_idx === 1) {
      setSid('')
    }
  }

  const handlePreviewImage = () => {
    window.wx.previewImage?.({
      urls: [
        goodsImageBig ||
          props.goods_spec_list[0].goods_image_big ||
          goodsImage ||
          props.goods_spec_list[0].goods_image,
      ],
    })
  }

  const skuBtnMemo = useMemo(() => {
    if (skuArr && skuArr.length > 0)
      return (
        specLists &&
        specLists.length > 0 &&
        specLists.map((item, father_idx) => (
          <div
            className="row-line-box"
            style={{
              padding: '3px 10px',
              border: 'none',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
            key={father_idx}
          >
            <div
              style={{
                width: '100%',
                alignSelf: 'flex-start',
                marginTop: 16,
                textAlign: 'left',
                color: '#333',
              }}
            >
              {item.name}
            </div>
            <ul
              className="row-line-box"
              style={{
                padding: 0,
                width: '100%',
                border: 'none',
                flexWrap: 'wrap',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              {item.spec_inner.map((its, isd) => {
                if (cid && sid) {
                  if (father_idx === 0) {
                    let ite = skuArr.find(
                      (item) =>
                        item['id'].indexOf(sid) > -1 &&
                        item['id'].indexOf(its.id) > -1
                    )
                    if (ite['id'].indexOf(cid) > -1) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn-active'}
                            data-fid={item.father_id}
                            onClick={(e) =>
                              cancelSpecActive(its.id, father_idx)
                            }
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else if (
                      ite['id'].indexOf(cid) === -1 &&
                      ite['goods_storage'] === 0
                    ) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-disabled-btn'}
                            onClick={() => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn'}
                            data-fid={item.father_id}
                            onClick={(e) => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    }
                  } else if (father_idx === 1) {
                    let ite = skuArr.find(
                      (item) =>
                        item['id'].indexOf(cid) > -1 &&
                        item['id'].indexOf(its.id) > -1
                    )
                    if (ite['id'].indexOf(sid) > -1) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn-active'}
                            data-fid={item.father_id}
                            onClick={(e) =>
                              cancelSpecActive(its.id, father_idx)
                            }
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else if (
                      ite['id'].indexOf(sid) === -1 &&
                      ite['goods_storage'] === 0
                    ) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-disabled-btn'}
                            onClick={() => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn'}
                            data-fid={item.father_id}
                            onClick={(e) => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    }
                  }
                  if (its.id === cid || its.id === sid) {
                    return (
                      <li key={its.id}>
                        <button
                          className={'spec-btn-active'}
                          data-fid={item.father_id}
                          onClick={(e) => cancelSpecActive(its.id, father_idx)}
                        >
                          {its.name}
                        </button>
                      </li>
                    )
                  } else {
                    for (let x = 0; x < skuArr.length; x++) {
                      if (skuArr[x]['id'].indexOf(its.id) > -1) {
                        if (skuArr[x]['goods_storage'] === 0) {
                          return (
                            <li key={its.id}>
                              <button
                                className={'spec-disabled-btn'}
                                onClick={() =>
                                  switchSpecNow(its.id, father_idx)
                                }
                              >
                                {its.name}
                              </button>
                            </li>
                          )
                        } else {
                          return (
                            <li key={its.id}>
                              <button
                                className={'spec-btn'}
                                data-fid={item.father_id}
                                onClick={(e) =>
                                  switchSpecNow(its.id, father_idx)
                                }
                              >
                                {its.name}
                              </button>
                            </li>
                          )
                        }
                      }
                    }
                  }
                } else if (!cid && !sid) {
                  return (
                    <li key={its.id}>
                      <button
                        className={'spec-btn'}
                        data-fid={item.father_id}
                        onClick={(e) => switchSpecNow(its.id, father_idx)}
                      >
                        {its.name}
                      </button>
                    </li>
                  )
                } else if (!cid && sid) {
                  if (father_idx === 1) {
                    //上规格
                    if (its.id === sid) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn-active'}
                            data-fid={item.father_id}
                            onClick={(e) =>
                              cancelSpecActive(its.id, father_idx)
                            }
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn'}
                            data-fid={item.father_id}
                            onClick={(e) => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    }
                  } else if (father_idx === 0) {
                    const ite = skuArr.find(
                      (item) =>
                        item['id'].indexOf(sid) > -1 &&
                        item['id'].indexOf(its.id) > -1
                    )
                    return ite['goods_storage'] === 0 ? (
                      <li key={its.id}>
                        <button
                          className={'spec-disabled-btn'}
                          onClick={() => switchSpecNow(its.id, father_idx)}
                        >
                          {its.name}
                        </button>
                      </li>
                    ) : (
                      <li key={its.id}>
                        <button
                          className={'spec-btn'}
                          data-fid={item.father_id}
                          onClick={(e) => switchSpecNow(its.id, father_idx)}
                        >
                          {its.name}
                        </button>
                      </li>
                    )
                  }
                } else if (cid && !sid) {
                  if (skuNum === 1) {
                    //单规格
                    let ite = skuArr.find(
                      (item) => item['id'].indexOf(its.id) > -1
                    )
                    if (its.id === cid && ite['goods_storage'] !== 0) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn-active'}
                            data-fid={item.father_id}
                            onClick={(e) =>
                              cancelSpecActive(its.id, father_idx)
                            }
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else if (ite['goods_storage'] === 0) {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-disabled-btn'}
                            onClick={() => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    } else {
                      return (
                        <li key={its.id}>
                          <button
                            className={'spec-btn'}
                            data-fid={item.father_id}
                            onClick={(e) => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    }
                  } else {
                    //双规格
                    if (father_idx === 0) {
                      if (its.id === cid) {
                        return (
                          <li key={its.id}>
                            <button
                              className={'spec-btn-active'}
                              data-fid={item.father_id}
                              onClick={(e) =>
                                cancelSpecActive(its.id, father_idx)
                              }
                            >
                              {its.name}
                            </button>
                          </li>
                        )
                      } else {
                        return (
                          <li key={its.id}>
                            <button
                              className={'spec-btn'}
                              data-fid={item.father_id}
                              onClick={(e) => switchSpecNow(its.id, father_idx)}
                            >
                              {its.name}
                            </button>
                          </li>
                        )
                      }
                    } else if (father_idx === 1) {
                      let ite = skuArr.find(
                        (item) =>
                          item['id'].indexOf(cid) > -1 &&
                          item['id'].indexOf(its.id) > -1
                      )
                      return ite && ite['goods_storage'] === 0 ? (
                        <li key={its.id}>
                          <button
                            className={'spec-disabled-btn'}
                            onClick={() => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      ) : (
                        <li key={its.id}>
                          <button
                            className={'spec-btn'}
                            data-fid={item.father_id}
                            onClick={(e) => switchSpecNow(its.id, father_idx)}
                          >
                            {its.name}
                          </button>
                        </li>
                      )
                    }
                  }
                }
              })}
            </ul>
          </div>
        ))
      )
  }, [specLists, cid, sid, skuNum])

  return (
    <div className="goods-edit-modal">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 100,
          padding: '5px 10px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: '23%',
            borderRadius: 6,
            overflow: 'hidden',
            alignSelf: 'center',
          }}
          onClick={handlePreviewImage}
        >
          <img
            alt=""
            src={goodsImage || props.goods_spec_list[0].goods_image}
            style={{ width: '100%', borderRadius: 6 }}
          />
        </div>
        <div style={{ width: '73%', textAlign: 'left', fontSize: 16 }}>
          <h4>
            <span style={{ color: '#FF4F65' }}>
              ￥
              <span style={{ fontSize: '20px' }}>
                {goodsPrice || props.goods_spec_list[0].goods_price}
              </span>
            </span>
          </h4>
          <h4 style={{ fontSize: '14px', color: '#999' }}>库存：{RGS}</h4>
          {Number(RGS) ? (
            <p
              className={
                Number(props.goods_info.pre_sale) === 2
                  ? 'theme-color'
                  : 'theme-yellow-color'
              }
            >
              {props.goods_info.pre_sale_tip}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className="scrollable"
        style={{ maxHeight: 300, paddingBottom: '12px', overflowY: 'scroll' }}
      >
        {skuBtnMemo}
      </div>

      <div
        className="row-line-box"
        style={{
          borderBottom: 'none',
          borderTop: '1px solid #f4f4f4',
          padding: '5px 10px',
          current_goods_specmarginTop: 10,
        }}
      >
        <p style={{ color: '#333' }}>购买数量</p>
        {parseInt(props.is_quota) === 1 ? (
          <Stepper
            style={{ width: '30%', minWidth: '80px' }}
            min={1}
            max={RGS > props.quota_num ? props.quota_num : RGS}
            defaultValue={num}
            onChange={handleStepChange}
          />
        ) : (
          <Stepper
            style={{ width: '30%', minWidth: '80px' }}
            min={1}
            max={RGS}
            defaultValue={num}
            onChange={handleStepChange}
          />
        )}
      </div>
      {parseInt(props.is_quota) === 1 && (
        <p
          style={{
            paddingTop: 6,
            textAlign: 'right',
            color: 'red',
            paddingRight: 10,
          }}
        >
          该商品限购{props.quota_num}件
        </p>
      )}

      <div className="g-btn-group next-btn-group">
        {+props.activity_type === 7 ? (
          <button
            className="adding-btn-line"
            disabled={props.exchange == 0 || loading}
            onClick={() =>
              props.history.push(`/exchange?id=${props.activity_id}`)
            }
          >
            {props.exchange == 0 ? '活动已结束' : '查看换购活动'}
          </button>
        ) : (
          <>
            <button
              className={!props.if_warmingup ? 'adding-btn' : 'adding-btn-line'}
              disabled={loading}
              onClick={doAddingCart}
            >
              加入购物车
            </button>
            {!props.if_warmingup && (
              <button
                className="purchase-now-btn"
                disabled={loading}
                onClick={doBuyNow}
              >
                立即购买
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const ServiceContent = ({ content, contentType, handleCancel }) => {
  return (
    <div className="service-content-modal">
      {contentType === 2 ? (
        <>
          <div className="service-content-title">商品不发货地区</div>
          <div className="service-content scrollable">
            <NonDelivery list={content} />
          </div>
        </>
      ) : (
        <div
          className="service-content scrollable"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
      <div className="service-content-footer">
        <div className="service-content-btn" onClick={handleCancel}>
          好的
        </div>
      </div>
    </div>
  )
}

const AddWRouter = connect(mapStateToProps)(withRouter(AddCom))

export function Adding(props) {
  return <AddWRouter {...props} />
}

// 商品名称下方的规格条目
function Spec({ truncate = false, label, desc, onClick = () => {} }) {
  let textClassName = 'specifications-text'

  if (truncate) textClassName += ' truncate'

  return (
    <div className="specifications" onClick={onClick}>
      <div className={textClassName}>
        <span className="specifications-label">{label}</span>
        <span className={truncate ? 'truncate' : ''}>{desc}</span>
      </div>
      <Icon type="right" />
    </div>
  )
}

export default connect(mapStateToProps)(GoodsDetail)
